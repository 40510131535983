import React, {useState, useEffect} from 'react'
import { ChoiceBarHolder, Colors, DivHolder, NavBar, PublishButton } from './styles/styled.container'
import { ChoiceBox, ChoiceBoxMini } from './styles/styled.text'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase'
import { StyledAccountIcon, StyledExpandIcon } from './styles/styled.icons';
import Menu from '@mui/material/Menu';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { MenuItem } from '@mui/material';


function FindBar({link_url}) {

    const [value, setValue] = useState("")
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [userFound, setUserFound] = useState(false);
    
    
    useEffect(() => {
        if (loading) {
        return;
        }
        if(user) setUserFound(true)

    }, [user, loading]);

    const handleShareProfile = () => {

        if (navigator.share) {
          navigator.share({
            title: "Profile Card Link",
            url: `https://www.profilecard.co.za/${link_url}`,
          })
            .then(() => {
  
            })
            .catch((error) => {
              console.error('Error sharing:', error);
            });
        } else {
  
      
          // Fallback behavior: Open a new window or use any other share functionality
          const encodedUrl = encodeURIComponent(link_url);
          const shareUrl = `https://www.profilecard.co.za/${link_url}`;
          window.open(shareUrl, '_blank');
        }
      };
    

    function handleChangeValue(val) {
        setValue(val)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          const trimmedValue = value.toLowerCase(); 
          window.location.href = `/${trimmedValue}`;
        }
      };

  return (
    <NavBar>
        <ChoiceBarHolder align="flex-start" style={{'marginRight': '10px'}}>
        <ChoiceBoxMini
            placeholder='Find a person you know' 
            value={value}
            fullWidth
            size="small" 
            onKeyDown={handleKeyDown}
            onChange={(e) => handleChangeValue(e.target.value)}
            InputProps={{
                startAdornment: (
                    <StyledAccountIcon style={{'marginRight': '10px'}} color={Colors.dark_purple} />
                )
            }}
            
            inputProps={{
                
                style: {
                    fontFamily: "Poppins",
                    textAlign: "left",
                    
                    fontSize: '0.7rem'
                }
            }}
            >
            
        </ChoiceBoxMini>
        </ChoiceBarHolder>
        <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            
            <StyledExpandIcon fontSize='large' color={Colors.darkest_purple} {...bindTrigger(popupState)} />
            <Menu {...bindMenu(popupState)}>

              
            
              {userFound && <MenuItem onClick={() => navigate("/design")}>My Profile</MenuItem>}

              <MenuItem onClick={handleShareProfile}>Share Profile</MenuItem>
              {!userFound && <MenuItem onClick={() => navigate("/login")}>Login</MenuItem>}
              {!userFound && <MenuItem onClick={() => navigate("/register")}>Register</MenuItem>}

            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      
        {/* {userFound ? <PublishButton style={{'marginLeft': '30px'}} onClick={() => navigate("/design")}>Design</PublishButton> : 
        <DivHolder style={{'marginLeft': '30px'}} align="flex-end">
        {!loading && 
        <>
        <PublishButton onClick={() => navigate("/register")} style={{'marginRight': '10px'}}>Register</PublishButton>
        <PublishButton onClick={() => navigate("/login")} style={{'marginRight': '10px'}}>Login</PublishButton>
        </>
        }
        </DivHolder>} */}
    </NavBar>
  )
}

export default FindBar