import styled from "styled-components"
import { keyframes } from "styled-components"
import { Colors } from "./styled.container"

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
`;

const frames = keyframes`
0% { transform: rotate(0deg);}
100% { transform: rotate(360deg); }
`

export const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const LoaderDots = styled.div`
  display: flex;
  align-items: center;
`;

export const Dot = styled.div`
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border: 2px solid ${Colors.white};
  border-radius: 50%;
  background-color: ${Colors.dark_purple};
  animation: ${pulseAnimation} 1s ease-in-out infinite;
  animation-delay: ${props => props.delay}s;
`;

export const Spinner = styled.div`
    width: 50px;
    height: 50px;
    border: 6px solid ${Colors.blue};
    //border: 5px solid #ff006e; /* Light grey */
    border-top: 5px solid aliceblue; /* Black */
    border-radius: 50%;
    animation: ${frames} 1s linear infinite;

`

export const SpinnerMessage = styled.div`
    font-size: 0.9rem;
    margin-top: 20px;
    letter-spacing: 1.1px;
    font-weight: 600;
    color: ${Colors.white};
`