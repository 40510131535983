import styled from "styled-components";
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GitHubIcon from '@mui/icons-material/GitHub';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import HomeIcon from '@mui/icons-material/Home';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBehance } from '@fortawesome/free-brands-svg-icons';
import TwitterIcon from '@mui/icons-material/Twitter';
import SearchIcon from '@mui/icons-material/Search';
import ShareIcon from '@mui/icons-material/Share';
import CancelIcon from '@mui/icons-material/Cancel';
import { Colors } from "./styled.container";

const StyledFontAwesomeIcon = ({ icon, ...props }) => (
    <FontAwesomeIcon icon={icon} {...props} />
  );

export const StyledWebsiteIcon = styled(LanguageIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledInstagramIcon = styled(InstagramIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`
export const StyledFacebookIcon = styled(FacebookIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledLinkedInIcon = styled(LinkedInIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledRemoveIcon = styled(RemoveCircleIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledPhoneIcon = styled(SmartphoneIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledEmailIcon = styled(EmailIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledExpandIcon = styled(ExpandCircleDownIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    
    cursor: pointer;
`

export const StyledYoutubeIcon = styled(YouTubeIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledGithubIcon = styled(GitHubIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledBehanceIcon = styled(StyledFontAwesomeIcon).attrs({
    icon: faBehance,
  })`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
  `;

export const StyledAddressIcon = styled(HomeIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledPasswordIcon = styled(VisibilityIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledHidePasswordIcon = styled(VisibilityOffIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledCopyIcon = styled(FileCopyIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledAccountIcon = styled(AccountCircleIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledTwitterIcon = styled(TwitterIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledSearchIcon = styled(SearchIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledShareIcon = styled(ShareIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`

export const StyledCloseIcon = styled(CancelIcon)`
    color: ${props => props.color ? props.color : `${Colors.white}`};
    cursor: pointer;
`