import React, {useState, useEffect, useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import { CircleStencil, Cropper } from 'react-advanced-cropper';
import { ProfileImageViewerButton, ProfileImageViewerHolder, StyledCropper } from './styles/styled.profile';
import "react-advanced-cropper/dist/style.css";
import { ButtonContainer, Colors, PublishButton } from './styles/styled.container';
import Loader from './Loader';

export default function ProfileImageViewer(props) {
  const {open, setOpen, file, action} = props;
  const [image, setImage] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const cropperRef = useRef();

  useEffect(() => {
    const blob = URL.createObjectURL(file);
    setImage({
        src: blob,
        type: file.type
     })
  }, [file])

  useEffect(() => {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    return () => {
        if (image && image.src) {
            URL.revokeObjectURL(image.src);
        }
    };
    }, [image]);

  const handleClose = () => {
    setOpen(false);
  };

  function handleAction() {
    
    setShowLoading(true)
    if (action) {
        
        const canvas = cropperRef.current.getCanvas();
        canvas.toBlob((blob) => {
          // Call the action function with the cropped image as a blob
          action(blob, file);
        });
      } else {
        handleClose();
      }
  }

  return (
    <div>
        
      <Dialog
        open={open}
        fullScreen
        onClose={handleClose}>
            <ProfileImageViewerHolder>
            <Loader show={showLoading} />
            <StyledCropper
            ref={cropperRef}
            stencilProps={{
                aspectRatio: {
                  minimum: 2 / 4,
                  maximum: 2 / 4,
                },
              }}
            style={{'width': '100%', 'height': '90vh'}}
            stencilComponent={CircleStencil}
            src={image && image.src} />
            
            </ProfileImageViewerHolder>
            <ButtonContainer style={{'margin': '0px'}}>
            <ProfileImageViewerButton onClick={() => setOpen(false)} backgroundColor={`${Colors.dark_purple}`}>Close</ProfileImageViewerButton>
            <ProfileImageViewerButton onClick={handleAction} colour={`${Colors.dark_blue}`}>Select</ProfileImageViewerButton>
            
            </ButtonContainer>
            
      </Dialog>
    </div>
  );
}