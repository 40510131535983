import styled from "styled-components";


export const SectionHolder = styled.div`
    display: flex;
    padding: 20px 0px;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    background-image: url(${props => props.backgroundImage ? props.backgroundImage : ""});

    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    opacity: ${props => props.backgroundColor && props.backgroundImage ? 0.5 : 1}; /* Adjust the opacity value as desired */
  }
    
`

export const SectionContainer = styled.div`
    width: 100%;
    display: flex;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    padding: 30px 20px;
    position: relative;
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

export const SectionSettingsContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    margin-bottom: 5px;
    justify-content: flex-end;
`

export const SectionTitleContainer = styled.div`

text-align: left;
margin-bottom: 10px;
width: 100%;

${props => props.align === 'left' && `
        text-align: left;
`}

${props => props.align === 'center' && `
        text-align: center;
`}

${props => props.align === 'right' && `
        text-align: right;
`}

`

export const SectionDescriptionContainer = styled.div`
    text-align: left;
    width: 100%;
    max-width: 800px;

    ${props => props.align === 'left' && `
            text-align: left;
    `}

    ${props => props.align === 'center' && `
            text-align: center;
    `}

    ${props => props.align === 'right' && `
            text-align: right;
    `}
`

