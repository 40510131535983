import React, { useContext, useState } from 'react'
import { SectionContainer, SectionDescriptionContainer, SectionSettingsContainer, SectionTitleContainer } from './styles/styled.section'
import { Title, Text, SectionTitleText, SectionDescriptionText } from './styles/styled.text'

import AuthContext from './AuthContext';
import { StyledRemoveIcon } from './styles/styled.icons';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Align, Colors, ContentHolder, DesignButton, DesignImage, FileButton, Weights } from './styles/styled.container';
import { Sizes } from './styles/styled.container';
import { ColorPickerCloseButton, ColorPickerContainer, SettingsDetailItem, SettingsDetailItemBlock, SettingsDetailItemText, SettingsSection, SettingsTitle } from './styles/styled.settings';
import { ChromePicker } from 'react-color';
import WandImg from "./imgs/wand.png"
import { Input, SectionDescriptionInput, SectionTitleInput } from './Input';
import PexelsViewer from './PexelsViewer';


const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
    
    const {currentWindow} = props
    const [open, setOpen] = React.useState(props.open);
    const {profileInfo, setProfileInfo, profileDesign, setProfileDesign} = useContext(AuthContext)
    const [showBackgroundPicker, setShowBackgroundPicker] = useState(false)
    const [showTitlePicker, setShowTitlePicker] = useState(false);
    const [showDescriptionPicker, setShowDescriptionPicker] = useState(false)
    const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(profileDesign.sectionBackgroundColor);
    const [selectedTitleColor, setSelectedTitleColor] = useState(profileDesign.sectionTitleColor);
    const [selectedDescriptionColor, setSelectedDescriptionColor] = useState(profileDesign.sectionDescriptionColor);
    const [showLoading, setShowLoading] = useState(false);
    const [showPexelsDialog, setShowPexelsDialog] = useState(false)

    const handleSectionBackgroundImageClick = () => {
        //heroBackgroundInputRef.current.click();
        setShowPexelsDialog(true)
    };

    function handlePexelsChangeSectionBackground(src, photographer) {
      setShowPexelsDialog(false)
      setProfileInfo(prevState => ({...prevState,
        section_background_image: src
      }));

    }

    function handleRemoveBackgroundImage() {
      setProfileInfo(prevState => ({...prevState, section_background_image: ""}));
    }

    const handleBackgroundTextFieldClick = () => {

        setShowBackgroundPicker(true);
      };

    const handleTextFieldClick = () => {

      setShowTitlePicker(true);
    };

    const handleDescriptionFieldClick = () => {

      setShowDescriptionPicker(true)
    }

    const handleBackgroundColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        setSelectedBackgroundColor(rgbaColor);
        setProfileDesign(prevState => ({...prevState, sectionBackgroundColor: rgbaColor}))
      };
  
    const handleTitleColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedTitleColor(rgbaColor)
      setProfileDesign(prevState => ({...prevState, sectionTitleColor: rgbaColor}))
    };

    const handleDescriptionColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedDescriptionColor(rgbaColor)
      setProfileDesign(prevState => ({...prevState, sectionDescriptionColor: rgbaColor}))
    };
  
    const handlePickerClose = () => {
      setShowDescriptionPicker(false)
      setShowTitlePicker(false);
      setShowBackgroundPicker(false);
    };
    
    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

  
    // This is used only for the example
    const container = currentWindow !== undefined ? () => currentWindow().document.body : undefined;

    function resetBackgroundColor(e) {
        e.stopPropagation();
        setSelectedBackgroundColor("none")
        setProfileDesign(prevState => ({...prevState, sectionBackgroundColor: Colors.transparent}))
    }

    function handleTitleSizeChange(e) {
      
      setProfileDesign(prevState => ({...prevState, sectionTitleSize: e.target.value}))

    }

    function handleTitleAlignChange(e) {
      
      setProfileDesign(prevState => ({...prevState, sectionTitleAlign: e.target.value}))

    }

    function handleTitleWeightChange(e) {
        setProfileDesign(prevState => ({...prevState, sectionTitleWeight: e.target.value}))

    }

    function handleDescriptionSizeChange(e) {
      
      setProfileDesign(prevState => ({...prevState, sectionDescriptionSize: e.target.value}))

    }

    function handleDescriptionAlignChange(e) {
      
      setProfileDesign(prevState => ({...prevState, sectionDescriptionAlign: e.target.value}))

    }

    function handleDescriptionWeightChange(e) {
        setProfileDesign(prevState => ({...prevState, sectionDescriptionWeight: e.target.value}))

    }
  
    return (
      <Root>
        <CssBaseline />
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(40% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
          }}
        />

        

        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={props.onClose}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          sx={{
            '& .MuiDrawer-paper': {
              maxWidth: 800,
              margin: '0 auto',
            },
          }}
          disableSwipeToOpen={false}
          ModalProps={{
            BackdropProps: {
              invisible: true, // Disable the backdrop
            },
            keepMounted: true,
            disableScrollLock: true
          }}
        >
          
        {showPexelsDialog && <PexelsViewer 
        open={showPexelsDialog} 
        setOpen={setShowPexelsDialog} 
        action={handlePexelsChangeSectionBackground} />}
          
          <StyledBox
            sx={{
              position: 'absolute',
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Puller />
            <Typography sx={{ p: 2, color: 'text.secondary' }}>Section Settings</Typography>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: '100%',
              overflow: 'auto',
            }}
          >
            <SettingsSection>
            {showBackgroundPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.sectionBackgroundColor}
              onChange={handleBackgroundColorChange}
              onClick={handleBackgroundTextFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            
              
              <SettingsDetailItemBlock>
                <SettingsTitle style={{'marginRight': '10px'}}>Background</SettingsTitle>
              <SettingsDetailItem>

                <TextField
                size='small'
                value={selectedBackgroundColor}
                onClick={handleBackgroundTextFieldClick}
                fullWidth
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <StyledRemoveIcon color={Colors.dark_blue} onClick={(e) => resetBackgroundColor(e)} fontSize='medium' />
                      </InputAdornment>
                    ),
                  }}
              />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
                
              <SettingsDetailItem>
              <SettingsTitle style={{'marginRight': '10px'}}>Section Image</SettingsTitle>
                
                <FileButton backgroundColor={`${Colors.dark_purple_plus}`} onClick={handleSectionBackgroundImageClick}>{profileInfo.section_background_image?.length > 0 ? "Selected" : "Choose file"}</FileButton>
                <StyledRemoveIcon color={`${Colors.dark_purple_plus}`} onClick={handleRemoveBackgroundImage} />

              
              </SettingsDetailItem>
            </SettingsDetailItemBlock>

            </SettingsSection>
            <SettingsSection>
            {showTitlePicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.sectionTitleColor}
              onChange={handleTitleColorChange}
              onClick={handleTextFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            <SettingsTitle>Section Title</SettingsTitle>
            <SettingsDetailItemBlock>
              
            <SettingsDetailItem>
                <SettingsDetailItemText>Size</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.sectionTitleSize}
                  onChange={handleTitleSizeChange}
                  fullWidth
                >
                  {Object.entries(Sizes).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Weight</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.sectionTitleWeight}
                  onChange={handleTitleWeightChange}
                  fullWidth
                >
                  {Object.entries(Weights).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Align</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.sectionTitleAlign}
                  onChange={handleTitleAlignChange}
                  fullWidth
                >
                  {Object.entries(Align).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Colour</SettingsDetailItemText>
                <TextField
                size='small'
                inputProps={{ readOnly: true }}
                value={selectedTitleColor}
                onClick={handleTextFieldClick}
                fullWidth
              />
            </SettingsDetailItem>

            </SettingsDetailItemBlock>
            
            </SettingsSection>

            <SettingsSection>

            {showDescriptionPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.sectionDescriptionColor}
              onChange={handleDescriptionColorChange}
              onClick={handleDescriptionFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            <SettingsTitle>Section Description</SettingsTitle>
            <SettingsDetailItemBlock>
              
            <SettingsDetailItem>
                <SettingsDetailItemText>Size</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.sectionDescriptionSize}
                  onChange={handleDescriptionSizeChange}
                  fullWidth
                >
                  {Object.entries(Sizes).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Weight</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.sectionDescriptionWeight}
                  onChange={handleDescriptionWeightChange}
                  fullWidth
                >
                  {Object.entries(Weights).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Align</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.sectionDescriptionAlign}
                  onChange={handleDescriptionAlignChange}
                  fullWidth
                >
                  {Object.entries(Align).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Colour</SettingsDetailItemText>
                <TextField
                size='small'
                inputProps={{ readOnly: true }}
                value={selectedDescriptionColor}
                onClick={handleDescriptionFieldClick}
                fullWidth
              />
            </SettingsDetailItem>

            </SettingsDetailItemBlock>
            
            </SettingsSection>
            
          </StyledBox>
        </SwipeableDrawer>
      </Root>
    );
  }

  function decodeHTMLEntities(text) {
    const element = document.createElement("textarea");
    element.innerHTML = text;

    //console.log(element.value)
    return element.value;
  }

const Section = React.memo(({preview, propProfileDesign, sectionInfo, handleUpdateSection, handleDeleteSection, index}) => {

  const {profileDesign} = useContext(AuthContext)

  const [showSettings, setShowSettings] = useState(false)
  const [sectionTitle, setSectionTitle] = useState(sectionInfo.title);
  const [sectionDescription, setSectionDescription] = useState(sectionInfo.description)

  return (
    <SectionContainer>
        {showSettings && !preview && <SwipeableEdgeDrawer open={true} onClose={() => setShowSettings(false)} />}
        {index === 0 && !preview && 
        <DesignButton marginTop="-5px" onClick={() => setShowSettings(true)}>
        <DesignImage src={WandImg} />
      </DesignButton>}
      
        <ContentHolder>
        
        <SectionTitleContainer align={preview ? propProfileDesign.sectionTitleAlign : profileDesign.sectionTitleAlign}>

            {preview ?
            
            <Text 
                colour={propProfileDesign.sectionTitleColor}
                size={propProfileDesign.sectionTitleSize}
                lineHeight={'1.5'}
                align={propProfileDesign.sectionTitleAlign}
                weight={propProfileDesign.sectionTitleWeight}
              >{decodeHTMLEntities(sectionInfo.title)}</Text>
            
            :

            <SectionTitleInput myVal={sectionInfo.title}  sectionInfo={sectionInfo} handleDeleteSection={handleDeleteSection} />
          }
                
        </SectionTitleContainer>

        <SectionDescriptionContainer align={preview ? propProfileDesign.sectionDescriptionAlign : profileDesign.sectionDescriptionAlign}>
        {preview ?
          <Text 
          colour={propProfileDesign.sectionDescriptionColor}
          size={propProfileDesign.sectionDescriptionSize}
          lineHeight={'1.5'}
          align={propProfileDesign.sectionDescriptionAlign}
          weight={propProfileDesign.sectionDescriptionWeight}
        >{decodeHTMLEntities(sectionInfo.description)}</Text>
        :
        <SectionDescriptionInput myVal={sectionInfo.description} sectionInfo={sectionInfo} />
        }
        </SectionDescriptionContainer>
        </ContentHolder>
        
    </SectionContainer>
  )
})

export default Section