import React, { useEffect, useState } from 'react'
import { createClient } from 'pexels';
import { PexelsButton, PexelsContainer, PexelsContainerClose, PexelsHolder, PexelsImage, PexelsImageContainer, PexelsInput, PexelsInputContainer } from './styles/styled.pexels';
import { Colors, PublishButton } from './styles/styled.container';
import Loader from './Loader';
import { StyledCloseIcon, StyledSearchIcon } from './styles/styled.icons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';

function PexelsViewer(props) {

const {open, setOpen, action} = props;
const [images, setImages] = useState();
const [query, setQuery] = useState("Nature");
const [showLoading, setShowLoading] = useState(false)
const [page, setPage] = useState(1)
const client = createClient('htUjiFO0TzY882d9oSRJYl2DbvxhhEj9cnc6oj9s0zU6iSkWLx7LVbV6');

    function handleAction(img) {
    //console.log(img);
    if(props.action) action(img.src.original, img.photographer);
    else handleClose();
  }

  const handleClose = () => {
    setOpen(false);
  };

useEffect(() => {
    getImages();
}, [page])

async function getImages() {
    setShowLoading(true)
    const fetchImages = await client.photos.search({ query, page: page, size: 'small', per_page: 15 });
    setImages(fetchImages.photos)
    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth' // You can change this to 'auto' for instant scrolling
    //   });
    setShowLoading(false)
}

function handleSearchQuery(event) {
    if(query.length === 0) return;

    if(event.key === "Enter") getImages()
}


function handleNextPage() {
    setPage(prevState => prevState + 1);
}

  return (
    <Dialog
        open={open}
        fullScreen
        onClose={handleClose}>
        
        <PexelsHolder>
    <Loader show={showLoading} />
    <PexelsContainer>
        <PexelsInputContainer>
            <StyledSearchIcon color={Colors.dark_blue} onClick={getImages} />
            <PexelsInput 
            onChange={(e) => setQuery(e.target.value)} 
            onKeyDown={handleSearchQuery} 
            placeholder='Enter the type of image you are looking for...' 
            type="text" />
        
        </PexelsInputContainer>

        <PexelsContainerClose onClick={() => setOpen(false)}><StyledCloseIcon fontSize='large' /></PexelsContainerClose>

        {images && images.map((image, i) => {
            
            return(
                <PexelsImageContainer onClick={() => handleAction(image)} key={i}>
                    <PexelsImage src={image.src.tiny} />
                </PexelsImageContainer>
            )
        })}

        
    </PexelsContainer>
    <PexelsButton onClick={handleNextPage}>Load More</PexelsButton>
    </PexelsHolder>
      </Dialog>
    
    
  )
}

export default PexelsViewer