import React, {useState, useEffect} from 'react'
import { DivHolder, NavBar, PublishButton } from './styles/styled.container'
import { LogoText } from './styles/styled.text'
import { Colors } from './styles/styled.container'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from './firebase'

function AppHeader() {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const [userFound, setUserFound] = useState(false);


    useEffect(() => {
        if (loading) {
        return;
        }
        if(user) setUserFound(true)

    }, [user, loading]);

  return (
    <NavBar>
        <DivHolder align="flex-start"><LogoText color={Colors.dark_purple_plus}>Profile card</LogoText></DivHolder>
        
        {userFound ? <PublishButton onClick={() => navigate("/design")}>Design</PublishButton> : 
        <DivHolder align="flex-end">
        {!loading && 
        <>
        <PublishButton onClick={() => navigate("/register")} style={{'marginRight': '10px'}}>Register</PublishButton>
        <PublishButton onClick={() => navigate("/login")} style={{'marginRight': '10px'}}>Login</PublishButton>
        </>
        }
        </DivHolder>}
        
      </NavBar>
  )
}

export default AppHeader