import styled from "styled-components";
import { Colors, Fonts } from "./styled.container";

export const PexelsHolder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${Colors.darkest_purple};
    padding: 0px 0px; 
    padding-bottom: 20px;
`

export const PexelsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
`

export const PexelsImageContainer = styled.div`
    display: flex;
    padding: 5px;
    cursor: pointer;
    width: 200px;
    height: 200px;
`

export const PexelsImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
`

export const PexelsInputContainer = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    background-color: ${Colors.white};
    align-items: center;
    margin-bottom: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`

export const PexelsInput = styled.input`
    outline: 0;
    border: 0;
    padding: 5px 10px;
    width: 100%;
    font-size: 0.9rem;
    color: ${Colors.dark_blue};
`

export const PexelsContainerClose = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
`

export const PexelsButton = styled.div`
    border-radius: 5px;
    padding: 15px 30px;
    text-align: center;
    margin: 10px;
    display: flex;
    font-family: ${Fonts.Nunito};
    align-self: center;
    width: 100%;
    text-align: center;
    justify-content: center;
    max-width: 280px;
    font-size: 0.8rem;
    cursor: pointer;
    text-transform: uppercase;
    color: ${Colors.white};
    font-weight: 700;
    background-color: ${Colors.dark_purple};
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: ${Colors.light_purple_plus};
        color: ${Colors.dark_blue};
    }
`