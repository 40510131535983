import React, { useState, useEffect } from 'react'
import { ChoiceContainer, DivHolder, LoginButton, PublishButton } from './styles/styled.container'
import ProfileImg from "./imgs/me.png"
import { ProfileContainer, ProfileHeroImage, ProfileHeroImageContainer } from './styles/styled.profile'
import { ChoiceBox, TextLink } from './styles/styled.text'
import { useNavigate } from 'react-router-dom'
import { Colors, Sizes } from './styles/styled.container'
import { auth, logInWithEmailAndPassword, sendPasswordReset } from "./firebase";
import { useAuthState } from 'react-firebase-hooks/auth'
import { Snackbar } from '@mui/material'
import Loader from './Loader'

function PasswordReset() {

    const [email, setEmail] = useState("")
    const [user, loading] = useAuthState(auth);
    const [barMessage, setBarMessage] = useState("");
    const navigate = useNavigate()
    const [showLoading, setShowLoading] = useState(false);
    const [openBar, setOpenBar] = useState(false);

    useEffect(() => {
        if (loading) {
          return;
        }
        if (user) navigate("/design");
      }, [user, loading]);

      const handleCloseBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenBar(false);
      };

    function handleEmailChange(val) {
        setEmail(val);
    }

    function isValidEmail(email) {     
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
      }

    async function handlePasswordReset() {
        if(email.length === 0 || !isValidEmail(email)) {
        setBarMessage("Please enter a valid email.");
        setOpenBar(true);
        return
        }

        setShowLoading(true)
        
        const result = await sendPasswordReset(email);
        setShowLoading(false)
        if(result.status) {
            setBarMessage("Password instructions have been emailed.")
            setOpenBar(true)
        }
        else {
            setBarMessage(result.message)
            setOpenBar(true)
        }

        
    }


  return (
    <ProfileContainer>
        <Loader show={showLoading} />
        <Snackbar
        open={openBar}
        autoHideDuration={3000}
        onClose={handleCloseBar}
        message={barMessage}
        action={null}
      />
        <ChoiceContainer>
        <ProfileHeroImageContainer style={{'marginBottom': '50px'}}>
            <ProfileHeroImage src={ProfileImg} />
        </ProfileHeroImageContainer>
        <DivHolder width={"70%"} column style={{'marginBottom': '30px'}}>
        <ChoiceBox
            
            size="small"
            placeholder='Enter your email' 
            value={email} 
            onChange={(e) => handleEmailChange(e.target.value)}
            inputProps={{
                style: {
                    fontFamily: "Poppins",
                    textAlign: "center",
                    fontSize: "0.8rem"
                }
            }}
            >
            
        </ChoiceBox>
        </DivHolder>
        
        <DivHolder column>
        <LoginButton onClick={handlePasswordReset} style={{'marginBottom': '10px'}} fullWidth>Reset Password</LoginButton>
        <TextLink onClick={() => navigate("/login")} colour={Colors.white} size={Sizes.xtiny}>Back to login</TextLink>
        
        </DivHolder>
        
    </ChoiceContainer>
    </ProfileContainer>
    
  )
}

export default PasswordReset