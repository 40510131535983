import React from 'react'
import { Colors, FooterBar, Sizes, Weights } from './styles/styled.container';
import { LogoText, Text, TextLink } from './styles/styled.text';

function AppFooter({photographer}) {
    function openUrl(url) {
        window.open(url, '_blank');
      }



  return (

    <FooterBar onClick={() => openUrl("https://www.prolificsolutions.co.za")}>

                <LogoText style={{'marginBottom': '2px'}} color={Colors.dark_purple_plus}>Profile card</LogoText>
                <Text textTransform={"uppercase"} weight={Weights.bold} colour={Colors.dark_purple_plus} size={'0.6rem'}>Designed by Prolific Solutions</Text>
                {photographer && <TextLink
                  onClick={() => openUrl("https://www.pexels.com")} 
                  textTransform="uppercase" 
                  weight={Weights.bold} 
                  size='0.6rem' 
                  colour={Colors.dark_blue}>
                    Image by {photographer} - By Pexels
                  </TextLink>}
                </FooterBar>
  )
}

export default AppFooter