import React, {useContext, useState} from 'react'
import { ProfileFooterContainer } from './styles/styled.profile'
import AuthContext from './AuthContext'
import { Title } from './styles/styled.text'
import { ContentHolder, DesignButton, DesignImage, FileButton, Sizes } from './styles/styled.container'
import { Checkbox, Divider, FormControlLabel, InputAdornment, MenuItem, TextField } from '@mui/material'
import ContactBar from './ContactBar'
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Align, Colors, Weights } from './styles/styled.container';
import { Text } from './styles/styled.text';
import { ColorPickerCloseButton, ColorPickerContainer, SettingsDetailItem, SettingsDetailItemBlock, SettingsDetailItemText, SettingsSection, SettingsTitle } from './styles/styled.settings';
import { ChromePicker } from 'react-color';
import { StyledRemoveIcon } from './styles/styled.icons'
import WandImg from "./imgs/wand.png"
import { Input } from './Input'
import { CheckBox } from '@mui/icons-material'
import PexelsViewer from './PexelsViewer'

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
    
    const {currentWindow} = props
    const [open, setOpen] = React.useState(props.open);
    const {profileDesign, setProfileDesign, profileInfo, setProfileInfo} = useContext(AuthContext)
    const [showFooterBackgroundPicker, setShowFooterBackgroundPicker] = useState(false);
    const [showFooterTextPicker, setShowFooterTextPicker] = useState(false);
    const [showContactPicker, setShowContactPicker] = useState(false);
    const [selectedFooterBackgroundColor, setSelectedFooterBackgroundColor] = useState(profileDesign.footerBackgroundColor);
    const [selectedFooterDescriptionColor, setSelectedFooterDescriptionColor] = useState(profileDesign.footerDescriptionColor);
    const [selectedContactColor, setSelectedContactColor] = useState(profileDesign.contactDescriptionColor);

    const [showLoading, setShowLoading] = useState(false);
    const [showPexelsDialog, setShowPexelsDialog] = useState(false)

    const handleFooterBackgroundImageClick = () => {
        //heroBackgroundInputRef.current.click();
        setShowPexelsDialog(true)
    };

    function handlePexelsChangeFooterBackground(src, photographer) {
      setShowPexelsDialog(false)
      setProfileInfo(prevState => ({...prevState,
        footer_background_image: src
      }));

    }

    function handleRemoveBackgroundImage() {
      setProfileInfo(prevState => ({...prevState, footer_background_image: ""}));
    }

    const handleBackgroundFieldClick = () => {

      setShowFooterBackgroundPicker(true);
    };

    const handleTextFieldClick = () => {

      setShowFooterTextPicker(true);
    };

    const handleContactFieldClick = () => {

        setShowContactPicker(true);
      };


    const handleBackgroundColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedFooterBackgroundColor(rgbaColor);
      setProfileDesign(prevState => ({...prevState, footerBackgroundColor: rgbaColor}))
    };
  
    const handleDescriptionColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedFooterDescriptionColor(rgbaColor)
      setProfileDesign(prevState => ({...prevState, footerDescriptionColor: rgbaColor}))
    };

    const handleContactColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedContactColor(rgbaColor)
      setProfileDesign(prevState => ({...prevState, contactDescriptionColor: rgbaColor}))
    };
  
    const handlePickerClose = () => {
      setShowFooterBackgroundPicker(false)
      setShowContactPicker(false)
      setShowFooterTextPicker(false)

    };
    
    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

  
    // This is used only for the example
    const container = currentWindow !== undefined ? () => currentWindow().document.body : undefined;

    function resetBackgroundColor(e) {
      e.stopPropagation();
      setSelectedFooterBackgroundColor("none")
      setProfileDesign(prevState => ({...prevState, footerBackgroundColor: Colors.transparent}))
    }


    function handleDescriptionSizeChange(e) {
      
      setProfileDesign(prevState => ({...prevState, footerDescriptionSize: e.target.value}))

    }

    function handleContactSizeChange(e) {
      
      setProfileDesign(prevState => ({...prevState, contactDescriptionSize: e.target.value}))

    }

    function handleDescriptionAlignChange(e) {
      
      setProfileDesign(prevState => ({...prevState, footerDescriptionAlign: e.target.value}))

    }

    function handleDescriptionWeightChange(e) {
      setProfileDesign(prevState => ({...prevState, footerDescriptionWeight: e.target.value}))

    }

    function handleContactSizeChange(e) {
      
      setProfileDesign(prevState => ({...prevState, contactDescriptionSize: e.target.value}))

    }

    function handleContactAlignChange(e) {
      
      setProfileDesign(prevState => ({...prevState, contactDescriptionAlign: e.target.value}))

    }

    function handleContactWeightChange(e) {
      setProfileDesign(prevState => ({...prevState, contactDescriptionWeight: e.target.value}))

    }

    function handleContactNumberChecked(e) {
      setProfileDesign(prevState => ({...prevState, showContactNumber: e.target.checked}))
    }

    function handleContactEmailChecked(e) {
      setProfileDesign(prevState => ({...prevState, showContactEmail: e.target.checked}))
    }

    function handleContactAddressChecked(e) {
      setProfileDesign(prevState => ({...prevState, showContactAddress: e.target.checked}))
    }
    
  
    return (
      <Root>
        <CssBaseline />
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(40% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
          }}
        />
        
        

        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={props.onClose}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          sx={{
            '& .MuiDrawer-paper': {
              maxWidth: 800,
              margin: '0 auto',
            },
          }}
          ModalProps={{
            BackdropProps: {
              invisible: true, // Disable the backdrop
            },
            keepMounted: true,
            disableScrollLock: true
          }}
        >
          
          <PexelsViewer 
          open={showPexelsDialog} 
          setOpen={setShowPexelsDialog} 
          action={handlePexelsChangeFooterBackground} />
          
          <StyledBox
            sx={{
              position: 'absolute',
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Puller />
            <Typography sx={{ p: 2, color: 'text.secondary' }}>Footer Settings</Typography>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: '100%',
              overflow: 'auto',
            }}
          >
            <SettingsSection>
            {showFooterBackgroundPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.footerBackgroundColor}
              onChange={handleBackgroundColorChange}
              onClick={handleBackgroundFieldClick}
              onClose={handlePickerClose}
            />
            
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            
              
              <SettingsDetailItemBlock>
                <SettingsTitle style={{'marginRight': '10px'}}>Background</SettingsTitle>
              <SettingsDetailItem>

                <TextField
                size='small'
                value={selectedFooterBackgroundColor}
                onClick={handleBackgroundFieldClick}
                fullWidth
                
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledRemoveIcon color={Colors.dark_blue} onClick={(e) => resetBackgroundColor(e)} fontSize='medium' />
                    </InputAdornment>
                  ),
                }}
              />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
                
              <SettingsDetailItem>
                
              <SettingsTitle style={{'marginRight': '10px'}}>Footer Image</SettingsTitle>
                
                <FileButton backgroundColor={`${Colors.dark_purple_plus}`} onClick={handleFooterBackgroundImageClick}>{profileInfo.hero_background_image?.length > 0 ? "Selected" : "Choose file"}</FileButton>
                <StyledRemoveIcon color={`${Colors.dark_purple_plus}`} onClick={handleRemoveBackgroundImage} />

              
              </SettingsDetailItem>
            </SettingsDetailItemBlock>
            
            </SettingsSection>
            <SettingsSection>
            {showFooterTextPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.footerDescriptionColor}
              onChange={handleDescriptionColorChange}
              onClick={handleTextFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            
            <SettingsTitle>Description</SettingsTitle>
            <SettingsDetailItemBlock>
              
            <SettingsDetailItem>
                <SettingsDetailItemText>Size</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.footerDescriptionSize}
                  onChange={handleDescriptionSizeChange}
                  fullWidth
                >
                  {Object.entries(Sizes).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                  
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Weight</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.footerDescriptionWeight}
                  onChange={handleDescriptionWeightChange}
                  fullWidth
                >
                  {Object.entries(Weights).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Align</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.footerDescriptionAlign}
                  onChange={handleDescriptionAlignChange}
                  fullWidth
                >
                  {Object.entries(Align).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Colour</SettingsDetailItemText>
                <TextField
                size='small'
                inputProps={{ readOnly: true }}
                value={selectedFooterDescriptionColor}
                onClick={handleTextFieldClick}
                fullWidth
              />
            </SettingsDetailItem>

            

            </SettingsDetailItemBlock>
            
            </SettingsSection>

            <SettingsSection>

            {showContactPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.contactDescriptionColor}
              onChange={handleContactColorChange}
              onClick={handleContactFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            <SettingsTitle>Contact</SettingsTitle>
            <SettingsDetailItem>
              
            <FormControlLabel
              control={<Checkbox checked={profileDesign.showContactNumber} onChange={handleContactNumberChecked} />}
              label={<SettingsDetailItemText fullWidth>Show contact number</SettingsDetailItemText>}
            />
            
          
            </SettingsDetailItem>

            <Divider />
            <SettingsDetailItem>
              
            <FormControlLabel
              control={<Checkbox checked={profileDesign.showContactEmail} onChange={handleContactEmailChecked} />}
              label={<SettingsDetailItemText fullWidth>Show contact email</SettingsDetailItemText>}
            />
          
            </SettingsDetailItem>
            <Divider />
            <SettingsDetailItem>
              
            <FormControlLabel
              control={<Checkbox checked={profileDesign.showContactAddress} onChange={handleContactAddressChecked} />}
              label={<SettingsDetailItemText fullWidth>Show contact address</SettingsDetailItemText>}
            />
          
            </SettingsDetailItem>
            <SettingsDetailItemBlock>
              
            <SettingsDetailItem>
                <SettingsDetailItemText>Size</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.contactDescriptionSize}
                  onChange={handleContactSizeChange}
                  fullWidth
                >
                  {Object.entries(Sizes).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Weight</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.contactDescriptionWeight}
                  onChange={handleContactWeightChange}
                  fullWidth
                >
                  {Object.entries(Weights).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Align</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.contactDescriptionAlign}
                  onChange={handleContactAlignChange}
                  fullWidth
                >
                  {Object.entries(Align).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Colour</SettingsDetailItemText>
                <TextField
                size='small'
                inputProps={{ readOnly: true }}
                value={selectedContactColor}
                onClick={handleContactFieldClick}
                fullWidth
              />
            </SettingsDetailItem>

            </SettingsDetailItemBlock>
            
            </SettingsSection>
            
          </StyledBox>
        </SwipeableDrawer>
      </Root>
    );
  }

  function decodeHTMLEntities(text) {
    const element = document.createElement("textarea");
    element.innerHTML = text;
    return element.value;
  }

function Footer({preview, propProfileDesign, propProfileInfo}) {

    const {profileDesign, setProfileDesign, profileInfo, setProfileInfo} = useContext(AuthContext)
    const [showSettings, setShowSettings] = useState(false);

    function handleProfileFooterChange(e) {
        setProfileInfo((prevState) => ({ ...prevState, footer: e.target.value }));
    }

  return (
    
    <ProfileFooterContainer backgroundImage={preview ? propProfileInfo?.footer_background_image : profileInfo?.footer_background_image}  backgroundColor={preview ? propProfileDesign.footerBackgroundColor : profileDesign.footerBackgroundColor}>
        {showSettings && !preview && <SwipeableEdgeDrawer open={true} onClose={() => setShowSettings(false)} />}
        {!preview &&
        <DesignButton marginTop="10px" onClick={() => setShowSettings(true)}>
        <DesignImage src={WandImg} />
      </DesignButton>}
      
        <ContentHolder>
        {preview ?

          <Text 
          colour={propProfileDesign.footerDescriptionColor}
          size={propProfileDesign.footerDescriptionSize}
          lineHeight={'1.5'}
          align={propProfileDesign.footerDescriptionAlign}
          weight={propProfileDesign.footerDescriptionWeight}
          >{decodeHTMLEntities(propProfileInfo.footer)}</Text>
        :
        <Input 
                placeholder={"Enter a footer description"}
                field="footer"
                myVal={profileInfo.footer} 
                colour={profileDesign.footerDescriptionColor}
                size={profileDesign.footerDescriptionSize}
                align={profileDesign.footerDescriptionAlign}
                weight={profileDesign.footerDescriptionWeight}
                letterSpacing={"1.1px"}
                lineHeight={"1.3"}
                marginTop={"20px"}
                padding={"0px"}

              />}

                <ContactBar preview={preview} propProfileDesign={propProfileDesign} propProfileInfo={propProfileInfo} />
        </ContentHolder>
        
    </ProfileFooterContainer>
  )
}

export default Footer