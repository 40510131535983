import React, { useState, useEffect } from 'react'
import { ChoiceContainer, DivHolder, LoginButton, PublishButton, Sizes } from './styles/styled.container'
import ProfileImg from "./imgs/me.png"
import { ProfileContainer, ProfileHeroImage, ProfileHeroImageContainer } from './styles/styled.profile'
import { ChoiceBox, Text, TextLink } from './styles/styled.text'
import { StyledHidePasswordIcon, StyledPasswordIcon } from './styles/styled.icons'
import { InputAdornment } from '@mui/material'
import { Colors } from './styles/styled.container'
import { useNavigate } from 'react-router-dom'
import AppFooter from './AppFooter'
import { sendSignInLinkToEmail } from "firebase/auth";
import { auth, logInWithEmailAndPassword } from "./firebase";
import { useAuthState } from 'react-firebase-hooks/auth'
import Loader from './Loader'

function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const [user, loading] = useAuthState(auth);
    const [errorText, setErrorText] = useState("")
    const [showLoading, setShowLoading] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
          return;
        }
        if (user) navigate("/design");
      }, [user, loading]);

    function handleEmailChange(val) {
        setEmail(val);
    }

    function handlePasswordChange(val) {
        setPassword(val);
    }

    async function handleSignin() {
        if(email.length === 0 || password.length === 0) {
            console.log("Email empty");
            return
        }

        setShowLoading(true)
        const response = await logInWithEmailAndPassword(email, password);
        setShowLoading(false)
        if(response.status !== 200) {
          setErrorText("Please check your email and password.")
          
        }
        
    }

    const handleKeyDown = async (event) => {
      if (event.key === 'Enter') {
        await handleSignin()
      }
    };

 

  return (
    <ProfileContainer>
    <Loader show={showLoading} />
    <ChoiceContainer>
        <ProfileHeroImageContainer style={{'marginBottom': '50px'}}>
            <ProfileHeroImage src={ProfileImg} />
        </ProfileHeroImageContainer>
        <DivHolder width={"80%"} column  style={{'marginBottom': '30px'}}>
        <ChoiceBox
            
            size="small"
            placeholder='Enter your email' 
            value={email} 
            onChange={(e) => handleEmailChange(e.target.value)}
            inputProps={{
                style: {
                    fontFamily: "Poppins",
                    textAlign: "center",
                    fontSize: "0.8rem"
                }
            }}
            >
            
        </ChoiceBox>
        <ChoiceBox

            sx={{'marginTop': '15px'}}
            size="small"
            type={showPassword ? "text" : "password"}
            fontSize={"0.8rem"}
            placeholder='Enter your password' 
            value={password}
            onKeyDown={handleKeyDown} 
            onChange={(e) => handlePasswordChange(e.target.value)}
            inputProps={{
                style: {
                    fontFamily: "Poppins",
                    textAlign: "center",
                    fontSize: "0.8rem"
                }
            }}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? <StyledHidePasswordIcon color={Colors.dark_blue} onClick={(e) => setShowPassword(prevState => !prevState)} fontSize='medium' />  :
                    <StyledPasswordIcon color={Colors.dark_blue} onClick={(e) => setShowPassword(prevState => !prevState)} fontSize='medium' />}
                  </InputAdornment>
                ),
              }}
            >
            
        </ChoiceBox>

        <Text style={{'marginTop': '15px'}} color={Colors.light_purple_plus} size={Sizes.small}>{errorText}</Text>
        </DivHolder>
        
        <DivHolder column>
        <LoginButton onClick={handleSignin} style={{'marginBottom': '10px'}} fullWidth>Login</LoginButton>
        <TextLink onClick={() => navigate("/resetpassword")} colour={Colors.white} size={Sizes.xtiny}>Forgot my password</TextLink>
        
        
        {/* <LoginButton fullWidth onClick={() => navigate("/resetpassword")}>Forgot password</LoginButton> */}
        </DivHolder>
        
    </ChoiceContainer>
    <AppFooter />
    </ProfileContainer>
    
  )
}

export default Login