import styled from "styled-components";
import { Colors, Sizes } from "./styled.container";
import { TextField } from "@mui/material";

export const LogoText = styled.div`
    font-size: 0.8rem;
    font-weight: 700;
    cursor: pointer;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 1.1px;
    color: ${props => props.color ? props.color : `${Colors.white}`};

    @media (max-width: 500px) {
        font-size: 0.7rem;
    }
`

export const Text = styled.div`
    width: 100%;
    color: ${props => props.colour ? props.colour : Colors.white};
    text-align: ${props => props.align ? props.align : "center"};
    font-size: ${props => props.size ? props.size : "1.5rem"};
    font-weight: ${props => props.weight ? props.weight : 500};
    margin-top: ${props => props.marginTop ? props.marginTop : "0px"};
    position: ${props => props.position ? props.position : "static"};
    padding: ${props => props.padding ? props.padding : "0px"};
    white-space: pre-line;
    line-height: ${props => props.lineHeight ? props.lineHeight : "1.5"};
    letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : "1px"};
    text-transform: ${props => props.textTransform ? props.textTransform : "none"};

    @media (max-width: 700px) {
        font-size: ${props => {
          if (props.size === Sizes.super) {
            return Sizes.small;
          } else if (props.size === Sizes.xlarge) {
            return Sizes.large;
          } else if (props.size === Sizes.large) {
            return Sizes.medium;
          } else if (props.size === Sizes.medium) {
            return Sizes.small;
          } else {
            return props.size;
          }
        }};
        
    }
`

export const TextLink = styled.div`
    width: 100%;
    cursor: pointer;
    color: ${props => props.colour ? props.colour : Colors.white};
    text-align: ${props => props.align ? props.align : "center"};
    font-size: ${props => props.size ? props.size : "1.5rem"};
    font-weight: ${props => props.weight ? props.weight : 500};
    margin-top: ${props => props.marginTop ? props.marginTop : "0px"};
    position: ${props => props.position ? props.position : "static"};
    padding: ${props => props.padding ? props.padding : "0px"};
    
    line-height: ${props => props.lineHeight ? props.lineHeight : "1.5"};
    letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : "1px"};
    text-transform: ${props => props.textTransform ? props.textTransform : "none"};
    transition: all 0.2s ease-in-out;

    .a {
        text-decoration: none;
    }

    &:hover {
        color: ${Colors.light_purple};
    }
`

export const SectionTitleText = styled.input`
    font-size: 0.9rem;
    outline: 0;
    font-weight: 700;
    border: 0;
    letter-spacing: 1.1px;
    background-color: transparent;
    text-align: center;
    color: ${props => props.color ? props.color : `${Colors.white}`};
    font-size: ${props => props.size ? props.size : "1.5rem"}

`

export const SectionDescriptionText = styled.input`
    font-size: 0.9rem;
    outline: 0;

    border: 0;
    letter-spacing: 1.1px;
    background-color: transparent;
    text-align: center;
    color: ${props => props.color ? props.color : `${Colors.white}`};
    font-size: ${props => props.size ? props.size : "1.5rem"}

`

export const ChoiceBox = styled(TextField)`
  width: ${props => props.width ? props.width : "70%"};
  border-radius: 30px;
  max-width: 500px;
  background-color: ${Colors.white};
  padding: ${props => props.padding ? props.padding : "20px 0px"};
  text-align: center;
  font-weight: 500;
  font-size: ${props => props.fontSize ? props.fontSize : "1rem"};

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 0;
      outline: 0;
    }

    @media (max-width: 500px) {
        font-size: 0.8rem;
    }
`;

export const ChoiceBoxMini = styled(TextField)`
  width: ${props => props.width ? props.width : "70%"};
  border-radius: 30px;
  background-color: ${Colors.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: ${props => props.padding ? props.padding : "0px 0px"};
  text-align: left;
  max-width: 300px;
  font-weight: 500;
  font-size: ${props => props.fontSize ? props.fontSize : "0.8rem"};

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 0;
      outline: 0;
    }

    @media (max-width: 500px) {
        font-size: 0.8rem;
        width: 100%;
    }
`;


