import styled from "styled-components";

export const Colors = {
    transparent: "transparent",
    light_purple: "#e0aaff",
    light_purple_plus: "#c77dff",
    purple: "#9d4edd",
    purple_plus: "#7b2cbf",
    dark_purple: "#5a189a",
    dark_purple_plus: "#3c096c",
    very_dark_purple: "#240046",
    darkest_purple: "#10002b",
    white: "#ffffff",
    light: "#f8f9fa",
    off_grey: "#cad2c5",
    dark_blue: "#293241",
    deep_blue: "#000814",
    profile_background_default: "rgba(17, 3, 32, 1)" //"#110320"
}

export const Fonts = {
    'Poppins': 'Poppins',
    'Montserrat': 'Montserrat',
    'Open Sans': 'Open Sans',
    'Nunito': 'Nunito',
    'Prompt': 'Prompt'
}

export const Sizes = {
    xtiny: "0.8rem",
    tiny: '0.9rem',
    small: '1rem',
    medium: '1.5rem',
    large: '2rem',
    xlarge: '2.5rem',
    super: '3.2rem'
}

export const Align = {
    left: "left",
    center: "center",
    right: "right"
}

export const Weights = {
    light: "400",
    medium: "500",
    bold: "700",
    xbold: "800"
}

export const ProfileImageStyles = ["None", "Classic", "Modern", "Professional"]

export const PageContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    font-family: ${props => props.font ? props.font : "Poppins"};
    display: flex;
    flex-direction: column;
`

export const MainContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

`

export const HeroContentHolder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    width: 100%;
    border-radius: 10px;
    padding: 20px 10px;
    padding-bottom: 25px;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    max-width: 1024px;
    background-image: url(${props => props.backgroundImage ? props.backgroundImage : ""});

    background-size: cover;
    background-repeat: no-repeat;

    // with swipeable drawer without this profileimageholder gets distorted
    
    .css-1ytz6dp {
        height: auto;
    }
`

export const ContentHolder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    align-items: center;
    width: 100%;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    max-width: 1024px;
`

export const NavBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : `${Colors.white}`};
    padding: 10px 20px;

    @media (max-width: 500px) {
        padding: 10px;
    }

`

export const FooterBar = styled.div`
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Colors.white};
    padding: 10px 20px;
`

export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 2;
    margin: 20px 0px; 
    align-items: center;
`

export const ButtonNewSection = styled.div`
    background-color: ${Colors.dark_blue};
    border: 1px dashed ${Colors.white};
    border-radius: 30px;
    color: ${Colors.white};
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
    margin: 0px 20px;
    min-width: 250px;
    max-width: 400px;
    padding: 10px 30px;
    
`

export const ChoiceBarHolder = styled.div`
    display: flex;

    width: 100%;

`

export const DivHolder = styled.div`
    display: flex;

    width: ${props => props.width ? props.width : "fit-content"};
    flex-direction: ${props => props.column ? "column" : "row"};
    align-items: center;
    justify-content: ${props => props.align ? props.align : "center"};



    @media (max-width: 500px) {
        width: 100%;
    }
`

export const DesignButton = styled.div`
    position: absolute;
    top: 0;
    z-index: 10;
    width: 30px;
    height: 30px;
    cursor: pointer;
    right: 0;
    margin-right: 20px;
    margin-top: ${props => props.marginTop ? props.marginTop : "0px"};
`

export const DesignImageHolder = styled.div`
    width: 70px;
    height: 70px;
    max-width: 70px;
    max-height: 70px;
    display: flex;
    align-self: flex-start;
    margin-bottom: 10px;

`

export const DesignImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const FileButton = styled.div`
    background-color: ${props => props.backgroundColor ? props.backgroundColor : `${Colors.profile_background_default}`};
    color: ${Colors.white};
    margin-right: 10px;
    cursor: pointer;
    padding: 5px 30px;
    font-size: 0.8rem;
    border-radius: 20px;
`

export const PublishButton = styled.div`
    border-radius: 5px;
    padding: 10px 20px;
    text-align: center;
    font-size: 0.7rem;
    cursor: pointer;
    text-transform: uppercase;
    color: ${Colors.white};
    font-weight: 500;
    background-color: ${Colors.profile_background_default};
`

export const LoginButton = styled.div`
    border-radius: 10px;
    padding: 10px 40px;
    text-align: center;
    font-size: 0.8rem;
    width: ${props => props.fullWidth ? "100%" : "fit-content"};
    cursor: pointer;
    max-width: 250px;
    min-width: 200px;
    text-transform: uppercase;
    color: ${Colors.white};
    font-weight: 500;
    background-color: ${Colors.dark_purple_plus};
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: ${Colors.dark_purple};
    }
`

export const ChoiceContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ChoiceButton = styled.div`
    
`

export const ProfileLinkContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${Colors.dark_purple};
    color: ${Colors.white};
    padding: 7px 10px;
`

