import React, { useContext, useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import { ProfileContainer, ProfileHolder, ProfileMasterHolder } from './styles/styled.profile';
import { SectionHolder } from './styles/styled.section';
import Section from './Section';
import Footer from './Footer';
import HeroSection from './HeroSection';
import { ButtonContainer, ButtonNewSection, ProfileLinkContainer, Sizes } from './styles/styled.container';
import Header from './Header';
import { collection, doc, getDoc, getDocs, query, setDoc } from 'firebase/firestore';
import { auth, db } from './firebase';
import { useNavigate } from 'react-router-dom';
import { Colors } from './styles/styled.container';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Text } from './styles/styled.text';
import { StyledCopyIcon } from './styles/styled.icons';
import ProfileLinkComponent from './ProfileLinkComponent';
import Loader from './Loader';
import WelcomeDialog from './WelcomeDialog';
import AppFooter from './AppFooter';

function ProfileDesign() {
  const {
    profileMeta,
    setProfileMeta,
    profileDesign,
    setProfileDesign,
    profileInfo,
    setProfileInfo,
    socialSection,
    setSocialSection,
    sections,
    setSections,
    currentUser,
  } = useContext(AuthContext);
  const [user, loading, error] = useAuthState(auth);
  const [showLoading, setShowLoading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function getDetails() {
      if (loading) return;

      if (user) {
        setShowLoading(true);
        await getProfileDetails();
        setShowLoading(false);
      } else navigate("/");
    }

    getDetails();
  }, [user, loading, error]);

  async function getProfileDetails() {

    const profileRef = doc(db, "profiles/" + user.uid);
    const profileDoc = await getDoc(profileRef);
    if (profileDoc.exists()) {
      const profile_meta = profileDoc.data();
      setProfileMeta({...profile_meta, uid: user.uid});

      if (profile_meta.published) {
        const profileDetailsRef = doc(db, "profiles/" + user.uid + "/details/design");
        const profileDetailsDoc = await getDoc(profileDetailsRef);
        const profileDetailsData = profileDetailsDoc.data();

        const profileInfoRef = doc(db, "profiles/" + user.uid + "/details/info");
        const profileInfoDoc = await getDoc(profileInfoRef);
        const profileInfoData = profileInfoDoc.data();

        const profileSocialSectionRef = doc(db, "profiles/" + user.uid + "/details/social");
        const profileSocialSectionDoc = await getDoc(profileSocialSectionRef);
        const profileSocialSectionData = profileSocialSectionDoc.data();

        const sectionsRef = query(collection(db, "profiles/" + user.uid + "/details/sections/list"));
        const sectionsDocs = await getDocs(sectionsRef);
        const profile_sections = sectionsDocs.docs.map((sectionDoc) => {
          const data = sectionDoc.data();
          return { ...data, key: sectionDoc.id };
        });

        if (profileDetailsData) setProfileDesign(profileDetailsData);
        if (profileInfoData) setProfileInfo(profileInfoData);
        if (profileSocialSectionData) setSocialSection(profileSocialSectionData);
        if (profile_sections.length > 0) {
          const sortedSections = profile_sections.sort((a, b) => a.index - b.index);
          setSections(sortedSections);
        }


      } else {
        console.log("Profile not yet published");
 
      }
    } else {
      console.log("Profile design does not exist");
      navigate(-1);
    }
  }

  function generateRandomNumber() {
    const numbers = '0123456789';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let randomString = '';

    for (let i = 0; i < 5; i++) {
      randomString += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    for (let i = 0; i < 5; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return randomString;
  }

  function handleAddNewSection() {
    setSections((prevState) => [
      ...prevState,
      {
        index: prevState.length,
        id: generateRandomNumber(),
        title: "",
        description: "",
        uploaded: false,
      },
    ]);
  }

  function handleUpdateSection(sectionInfo) {
    setSections((prevState) =>
      prevState.map((section) => (section.id === sectionInfo.id ? sectionInfo : section))
    );
  }

  function handleDeleteSection(sectionInfo) {
    setSections((prevState) => prevState.filter((section) => section.id !== sectionInfo.id));
  }

  return (
    <ProfileMasterHolder>
    <ProfileContainer
            backgroundImage={profileInfo.background_image}
            background_color={profileDesign.profileBackgroundColor}>
      <Header user={user} />
      {!profileMeta.published && <WelcomeDialog open={showWelcome} setOpen={setShowWelcome} />}
      {/* {profileMeta.link_url && <ProfileLinkComponent />} */}
      <ProfileHolder>
        <Loader show={showLoading} />
        {profileDesign.showHero && <HeroSection preview={false} />}
        {profileDesign.showSections && (
          <SectionHolder backgroundImage={profileInfo?.section_background_image} backgroundColor={profileDesign.sectionBackgroundColor}>
            {sections.map((section, i) => (
              <Section
                key={section.id}
                index={i}
                sectionInfo={section}
                handleUpdateSection={handleUpdateSection}
                handleDeleteSection={handleDeleteSection}
              />
            ))}
            <ButtonContainer>
              <ButtonNewSection onClick={handleAddNewSection}>New section</ButtonNewSection>
            </ButtonContainer>
          </SectionHolder>
        )}
        {profileDesign.showFooter && <Footer preview={false} />}
      </ProfileHolder>
    </ProfileContainer>
    <AppFooter photographer={profileInfo.photographer} />
    </ProfileMasterHolder>
    
  );
}

export default ProfileDesign;
