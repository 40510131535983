import styled from "styled-components";
import { Colors, Fonts } from "./styled.container";
import { Cropper } from "react-advanced-cropper";

export const ProfileMasterHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ProfileContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${props => props.background_color ? props.background_color : `${Colors.darkest_purple}`};
    padding: 0px 0px;
    padding-top: ${props => props.paddingTop ? props.paddingTop : "0px"};
    background-image: url(${props => props.backgroundImage ? props.backgroundImage : ""});

    background-size: cover;
    background-repeat: no-repeat;

`

export const ProfileHolder = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-self: center;
`

export const ProfileHeroContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 20px 20px;
    
    min-height: 100vh;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${props => props.backgroundImage ? props.backgroundImage : ""});

    background-size: cover;
    background-repeat: no-repeat;

    position: relative;
    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
      opacity: ${props => props.backgroundColor && props.backgroundImage ? 0.5 : 1}; /* Adjust the opacity value as desired */
    }
    
`

export const ProfileHeroDescriptionContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 30px;
    max-width: 800px;
    padding: 0px 20px;

`

export const ProfileHeroImageContainer = styled.div`
  margin: 20px 0px;
  overflow: hidden;
  position: relative;
  border-radius: 100%;
  border-color: ${Colors.white};
  border-style: dashed;
  
  width: ${({ size }) => {
    if (size === 160) return '250px';
    if (size === 140) return '220px';
    if (size === 120) return '180px';
    if (size === 100) return '150px';
    if (size === 80) return '120px';
    return '150px';
  }};
  
  height: ${({ size }) => {
    if (size === 160) return '250px';
    if (size === 140) return '220px';
    if (size === 120) return '180px';
    if (size === 100) return '150px';
    if (size === 80) return '120px';
    return '150px';
  }};

  ${(props) => {

    if(props.design === "None") {
      return `
      border: 0;
      border-style: none;
      `;
    }

    if (props.design === 'Classic') {
      return '';
    } else if (props.design === 'Professional') {
      return `
        border-radius: 50%;
        border: 6px solid ${Colors.dark_blue};
        background-image: conic-gradient(${Colors.dark_purple}, ${Colors.dark_purple_plus}, transparent);
        background-size: 50% 50%;
        background-position: 100% 0;
        background-repeat: no-repeat;
      `;
    } else if (props.design === 'Modern') {
      return `
        border: 3px solid ${Colors.light_purple};
        box-shadow: 0 0 5px ${Colors.light_purple_plus};
      `;
    }
    return '';
  }}

  ${(props) => props.preview === false && `
    &:hover::before {
      content: 'edit';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      border-radius: 100%;
      cursor: pointer;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      color: ${Colors.white};
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      transition: background-color 0.3s ease;
    }
  `}

  
`;

export const ProfileHeroImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const ProfileSocialContainer = styled.div`
    display: flex;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    padding: 10px 30px;
    border-radius: 30px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;

`

export const ProfileSocialIconContainer = styled.div`
    display: flex;
    width: 100%;

    justify-content: center;
    align-items: center;
`

export const ProfileSocialIcon = styled.div`
    margin: 0px 10px;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
`

export const ProfileFooterContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    padding: 20px 20px;
    min-height: 100vh;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url(${props => props.backgroundImage ? props.backgroundImage : ""});

    background-size: cover;
    background-repeat: no-repeat;

    .css-1ytz6dp {
        height: auto;
    }

    ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    opacity: ${props => props.backgroundColor && props.backgroundImage ? 0.5 : 1}; /* Adjust the opacity value as desired */
  }
`

export const ProfileContactContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 20px 0px;
    padding-top: 40px;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : "transparent"};
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ProfileImageViewerHolder = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
`



export const StyledCropper = styled(Cropper)`

  max-height: 400px;
  display: flex;
`

export const ProfileImageViewerButton = styled.div`
  cursor: pointer;
  width: 100%;
  font-family: ${Fonts.Nunito};
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  justify-content: center;
  padding: 10px;
  font-size: 0.9rem;
  color: ${props => props.colour ? props.colour : `${Colors.white}`};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : `${Colors.light_purple_plus}`};
`