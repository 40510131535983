import React, { useContext, useState, useRef } from 'react'
import { DivHolder, FileButton, Fonts, NavBar, PublishButton, Sizes } from './styles/styled.container'
import { LogoText, Text } from './styles/styled.text'
import AuthContext from './AuthContext'

import { Checkbox, Divider, FormControlLabel, InputAdornment, MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Align, Colors, ContentHolder, DesignButton, DesignImage, Weights } from './styles/styled.container';
import { ColorPickerCloseButton, ColorPickerContainer, SettingsDetailItem, SettingsDetailItemBlock, SettingsDetailItemText, SettingsSection, SettingsTitle } from './styles/styled.settings';
import { ChromePicker } from 'react-color';
import { StyledExpandIcon, StyledFacebookIcon, StyledInstagramIcon, StyledLinkedInIcon, StyledRemoveIcon, StyledWebsiteIcon } from './styles/styled.icons';
import { CheckBox } from '@mui/icons-material'
import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import { db } from './firebase'
import Loader from './Loader'
import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom'
import Menu from '@mui/material/Menu';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PublishedDialog from './PublishedDialog'
import PexelsViewer from './PexelsViewer'

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
    
    const {currentWindow} = props
    const [open, setOpen] = React.useState(props.open);
    const {profileMeta, profileDesign, setProfileDesign, profileInfo, setProfileInfo } = useContext(AuthContext)
    const [showBackgroundPicker, setShowBackgroundPicker] = useState(false);
    const fileInputRef = useRef(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showProfileBackgroundLoading, setShowProfileBackgroundLoading] = useState(false);
    const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(profileDesign.profileBackgroundColor);
    const [showPexelsDialog, setShowPexelsDialog] = useState(false)

    const handleBackgroundTextFieldClick = () => {

      setShowBackgroundPicker(true);
    };

    const handleBackgroundImageClick = () => {
      //fileInputRef.current.click();
      setShowPexelsDialog(true)
    };

    function handlePexelsChangeProfileBackground(src, photographer) {
      setShowPexelsDialog(false)
      setProfileInfo(prevState => ({...prevState,
        photographer: photographer,  
        background_image: src
      }));

    }

    const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      if (file) {

        if (file.size > 5 * 1024 * 1024) {
          setShowSnackbar(true)
          return;
        }

        handleProfileBackgroundImageChange(file);
      }
    }

    function handleProfileBackgroundImageChange(file) {

      setShowProfileBackgroundLoading(true);
      const fileExtension = file.name.split('.').pop(); // Get the file extension
      const fileName = `background.${fileExtension}`; // Use a fixed filename like "profile.extension"
    
      const storageRef = ref(storage, `profiles/${profileMeta.uid}/${fileName}`);
    
      uploadBytes(storageRef, file)
        .then((snapshot) => {
          return getDownloadURL(snapshot.ref);
        })
        .then((downloadURL) => {
          setProfileInfo(prevState => ({...prevState, background_image: downloadURL}));
          setShowProfileBackgroundLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setShowProfileBackgroundLoading(false);
        });

    }

    function handleRemoveBackgroundImage() {
      setProfileInfo(prevState => ({...prevState, background_image: ""}));
    }

    const handleBackgroundColorChange = (color) => {
      
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedBackgroundColor(rgbaColor);
      setProfileDesign(prevState => ({...prevState, profileBackgroundColor: rgbaColor}))
    };

    const handleProfileFontChange = (e) => {
      setProfileDesign(prevState => ({...prevState, profileFont: e.target.value}))
    };

    function handleHeroSectionChecked(e) {
      setProfileDesign(prevState => ({...prevState, showHero: e.target.checked}))
    }

    function handleSectionsSectionChecked(e) {
      setProfileDesign(prevState => ({...prevState, showSections: e.target.checked}))
    }

    function handleFooterSectionChecked(e) {
      setProfileDesign(prevState => ({...prevState, showFooter: e.target.checked}))
    }
  
    const handlePickerClose = () => {

      setShowBackgroundPicker(false)

    };
    
    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

  
    // This is used only for the example
    const container = currentWindow !== undefined ? () => currentWindow().document.body : undefined;

    function resetBackgroundColor(e) {
      e.stopPropagation();
      setSelectedBackgroundColor("none")
      setProfileDesign(prevState => ({...prevState, profileBackgroundColor: Colors.transparent}))
    }

    function handleCloseBar() {
      setShowSnackbar(false)
    }

  
    return (
      <Root>
        <CssBaseline />
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(40% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
          }}
        />

        <PexelsViewer 
        open={showPexelsDialog} 
        setOpen={setShowPexelsDialog} 
        action={handlePexelsChangeProfileBackground} />

        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={props.onClose}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            BackdropProps: {
              invisible: true, // Disable the backdrop
            },
            keepMounted: true,
            disableScrollLock: true
          }}
        >

          <Loader show={showProfileBackgroundLoading} />
          <Snackbar
          open={showSnackbar}
          autoHideDuration={2500}
          onClose={handleCloseBar}
          message="Please choose an image smaller than 3mb."
          action={null}
        />
          
          <StyledBox
            sx={{
              position: 'absolute',
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Puller />
            <Typography sx={{ p: 2, color: 'text.secondary' }}>Page Settings</Typography>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: '100%',
              overflow: 'auto',
            }}
          >
            <SettingsSection>
            {showBackgroundPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.profileBackgroundColor}
              disableAlpha={false}
              onChange={handleBackgroundColorChange}
              onClick={handleBackgroundTextFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            
            <SettingsTitle style={{'marginRight': '10px'}}>Background</SettingsTitle>
              <SettingsDetailItemBlock>
                
              <SettingsDetailItem>
                <SettingsDetailItemText>Colour</SettingsDetailItemText>
                <TextField
                size='small'
                value={selectedBackgroundColor}
                onClick={handleBackgroundTextFieldClick}
                fullWidth
                inputProps={{ readOnly: true }}
                InputProps={{
                  
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledRemoveIcon color={Colors.dark_blue} onClick={(e) => resetBackgroundColor(e)} fontSize='medium' />
                    </InputAdornment>
                  ),
                }}
              />
              </SettingsDetailItem>
            </SettingsDetailItemBlock>
            <SettingsDetailItemBlock>
                
              <SettingsDetailItem>
                <SettingsDetailItemText>Image</SettingsDetailItemText>
                <FileButton backgroundColor={`${Colors.dark_purple_plus}`} onClick={handleBackgroundImageClick}>{profileInfo.background_image.length > 0 ? "Selected" : "Choose file"}</FileButton>
                <StyledRemoveIcon color={`${Colors.dark_purple_plus}`} onClick={handleRemoveBackgroundImage} />
                <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
              />
              </SettingsDetailItem>
            </SettingsDetailItemBlock>
            <SettingsDetailItemBlock>
                
              <SettingsDetailItem>
                <SettingsDetailItemText>Font</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.profileFont}
                  onChange={handleProfileFontChange}
                  fullWidth
                >
                  {Object.entries(Fonts).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
              
              </SettingsDetailItem>
            </SettingsDetailItemBlock>
            </SettingsSection>

            <SettingsSection>
            <SettingsTitle style={{'marginRight': '10px'}}>Sections</SettingsTitle>

            <SettingsDetailItem>
              
            <FormControlLabel
              control={<Checkbox checked={profileDesign.showHero} onChange={handleHeroSectionChecked} />}
              label={<SettingsDetailItemText fullWidth>Show hero section</SettingsDetailItemText>}
            />
          
            </SettingsDetailItem>

            <Divider />


            <SettingsDetailItem>
              
            <FormControlLabel
              control={<Checkbox checked={profileDesign.showSections} onChange={handleSectionsSectionChecked} />}
              label={<SettingsDetailItemText fullWidth>Show sections section</SettingsDetailItemText>}
            />
          
            </SettingsDetailItem>

            <Divider />
            
            <SettingsDetailItem>
              
            <FormControlLabel
              control={<Checkbox checked={profileDesign.showFooter} onChange={handleFooterSectionChecked} />}
              label={<SettingsDetailItemText fullWidth>Show footer section</SettingsDetailItemText>}
            />
          
            </SettingsDetailItem>
   
            </SettingsSection>
            
          </StyledBox>
        </SwipeableDrawer>
      </Root>
    );
  }
  

function Header({user}) {
    const {profileMeta, setProfileMeta, profileDesign, profileInfo, setProfileInfo, socialSection, sections, setSections, handleLogout} = useContext(AuthContext)
    const [showPexelsDialog, setShowPexelsDialog] = useState(false)

    const [showSettings, setShowSettings] = useState(false)
    const [showLoading, setShowLoading] = useState(false);
    const [openBar, setOpenBar] = useState(false);
    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const navigate = useNavigate();

    const handleCloseBar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenBar(false);
    };

    async function getProfileLink() {
      
      const linkUrlBase = user.email.substring(0, user.email.indexOf('@')); 
      let linkUrl = linkUrlBase;
      let uniqueLinkUrl = false;
      let numAttempts = 0;
      const maxAttempts = 9999; // Maximum number of attempts to generate a unique link_url

      while (!uniqueLinkUrl && numAttempts <= maxAttempts) {
        const linkUrlExists = await checkLinkUrlExists(linkUrl); // Function to check if link_url already exists
        //console.log("Link url", linkUrlExists)

        if (linkUrlExists) {
          numAttempts++;
          linkUrl = `${linkUrlBase}${generateRandomNumber()}`; // Append a 4-digit random number
        } else {
          uniqueLinkUrl = true;
        }
      }

      if(uniqueLinkUrl) {
        return linkUrl;
      }
      else return undefined;
    }

    const generateRandomNumber = () => {
      return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
    };
  
    const checkLinkUrlExists = async (linkUrl) => {
      try {
        
        //console.log("Trying to read")
        const profilesRef = collection(db, "profiles");
        const querySnapshot = await getDocs(query(profilesRef, where("link_url", "==", linkUrl)));
    
        return !querySnapshot.empty;
      } catch (error) {
        console.error("Error checking link_url existence:", error);
        return false;
      }
    };

    async function handlePublishProfile() {

        //console.log("Trying to publish profile", profileInfo);

        if(user) {

          setShowLoading(true)

          //console.log(profileMeta);

          if(!profileMeta.published || profileMeta.link_url === "") {
            //console.log("Creating link")
            const link = await getProfileLink()
            const updatedProfileMeta = {
              ...profileMeta,
              published: true,
              link_url: link.toLowerCase()
            }
            //console.log("Got link");

            await setDoc(doc(db, "profiles/" + user.uid), updatedProfileMeta)
            setProfileMeta(updatedProfileMeta);
          }

          
          //console.log("Profile is now being published - setting");

          await setDoc(doc(db, "profiles/" + user.uid + "/details/design"), profileDesign, {merge: true})
          await setDoc(doc(db, "profiles/" + user.uid + "/details/info"), profileInfo, {merge: true})
          await setDoc(doc(db, "profiles/" + user.uid + "/details/social"), socialSection, {merge: true})
          
          const sectionsRef = collection(db, `profiles/${user.uid}/details/sections/list`);

          const existingCloudSectionsSnapshot = await getDocs(sectionsRef);
          const existingCloudSections = existingCloudSectionsSnapshot.docs.map((doc) => ({ id: doc.id }));

          existingCloudSections.forEach(async cloudSection => {
            if (!sections.find((section) => section.key === cloudSection.id)) {
              const deletedSectionDocRef = doc(db, `profiles/${user.uid}/details/sections/list/${cloudSection.id}`);
              await deleteDoc(deletedSectionDocRef)
            }
            else {
              console.log("Found section", cloudSection.id)
            }
          })

          const newSections = await Promise.all([...sections].map(async section => {

            if(!section.uploaded)
            {
              const sectionRef = await addDoc(sectionsRef, {...section, 
                uploaded: true
              });
              return {...section, uploaded: true}
            }
            else {
              const sectionDocRef = doc(sectionsRef, section.key);
              await setDoc(sectionDocRef, section, {merge: true});
              return section;
            }
          }))

          setSections(newSections);

          console.log("Updated profile")
          setShowLoading(false)
          setOpenBar(true);
          setShowPublishDialog(true)
        } else {
          console.log("No user found")
        }
      
    }

    function handleShowSettings(popupState) {
      popupState.close()
      setShowSettings(true)
    }

    const handleShareProfile = () => {

      if (navigator.share) {
        navigator.share({
          title: "Connect with me using my profile card",
          url: `https://www.profilecard.co.za/${profileMeta.link_url}`,
        })
          .then(() => {

          })
          .catch((error) => {
            console.error('Error sharing:', error);
          });
      } else {

    
        // Fallback behavior: Open a new window or use any other share functionality
        const encodedUrl = encodeURIComponent(profileMeta.link_url);
        const shareUrl = `https://www.profilecard.co.za/${profileMeta.link_url}`;
        window.open(shareUrl, '_blank');
      }
    };

    function handlePreviewProfile() {
      window.open(`https://profilecard.co.za/${profileMeta.link_url}`, '_blank');
    }

    function handleGoHome() {
      navigate("/")
    }

    function handleDialogActionClose() {
      setShowPublishDialog(false)
    }

    function handlePexelsChangeProfileBackground(src, photographer) {
      setShowPexelsDialog(false)
      setProfileInfo(prevState => ({...prevState,
        photographer: photographer,  
        background_image: src
      }));

    }

  return (
    <>
    <Loader show={showLoading} />
    <PexelsViewer 
        open={showPexelsDialog} 
        setOpen={setShowPexelsDialog} 
        action={handlePexelsChangeProfileBackground} />

    <Snackbar
        open={openBar}
        autoHideDuration={3000}
        onClose={handleCloseBar}
        message="Profile updated."
        action={null}
      />

      {showPublishDialog && <PublishedDialog 
            open={showPublishDialog} 
            setOpen={setShowPublishDialog}
            link={`https://www.profilecard.co.za/${profileMeta.link_url}`}
            title="Your profile is published!"
            content="Now you can share your profile with the world."
            action={handleDialogActionClose} />}

    <NavBar>
        <DivHolder onClick={() => navigate("/")} align={"flex-start"}><LogoText color={Colors.dark_purple_plus}>Profile card</LogoText></DivHolder>
                
        {showSettings && <SwipeableEdgeDrawer 
        open={true} 
        onClose={() => setShowSettings(false)} />}
        
        <DivHolder align="flex-end">
        {user && <PublishButton onClick={handlePublishProfile} style={{'marginRight': '10px'}}>Publish</PublishButton>}
        <PopupState variant="popover" popupId="demo-popup-menu" >
        {(popupState) => (
          <React.Fragment >
            
            <StyledExpandIcon fontSize='large' color={Colors.dark_purple_plus} {...bindTrigger(popupState)} />
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleShowSettings(popupState)}>Profile Settings</MenuItem>
              <MenuItem onClick={() => setShowPexelsDialog(true)}>Change Background</MenuItem>
              <MenuItem onClick={handleShareProfile}>Share</MenuItem>
              <MenuItem onClick={handlePreviewProfile}>Preview</MenuItem>
              <MenuItem onClick={handleGoHome}>Find</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
        
        
        </DivHolder>
        
      </NavBar>
    </>
    
  )
}

export default Header