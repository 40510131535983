import React from 'react'
import { ProfileHeroContainer, ProfileHeroImage, ProfileHeroImageContainer } from './styles/styled.profile'
import { Colors, ContentHolder, LoginButton, Sizes, Weights } from './styles/styled.container'
import ProfileImg from "./imgs/me.png"
import { Text } from './styles/styled.text'
import { useNavigate } from 'react-router-dom'


function EmptyProfile() {
    const navigate = useNavigate();
  return (
    
    <ProfileHeroContainer>
        <ContentHolder>
            <ProfileHeroImageContainer>
                <ProfileHeroImage src={ProfileImg} />
            </ProfileHeroImageContainer>
            <Text colour={Colors.white} size={Sizes.large} weight={Weights.bold}>
                Ooops! Could not find this profile.
            </Text>
            <LoginButton style={{'marginTop': '20px'}} onClick={() => navigate("/")}>Back</LoginButton>
        </ContentHolder>
    </ProfileHeroContainer>
  )
}

export default EmptyProfile