import React, { useContext, useState } from 'react'
import { ChoiceContainer, DivHolder, LoginButton, PublishButton, Sizes } from './styles/styled.container'
import ProfileImg from "./imgs/me.png"

import { ProfileContainer, ProfileHeroImage, ProfileHeroImageContainer } from './styles/styled.profile'
import { ChoiceBox, Text } from './styles/styled.text'
import { StyledHidePasswordIcon, StyledPasswordIcon } from './styles/styled.icons'
import { InputAdornment } from '@mui/material'
import { Colors } from './styles/styled.container'
import { useNavigate } from 'react-router-dom'
import AppFooter from './AppFooter'
import { db, auth, createUser } from './firebase'
import { doc, getDoc } from 'firebase/firestore'
import InfoDialog from './InfoDialog'
import { createUserWithEmailAndPassword, sendSignInLinkToEmail, onAuthStateChanged } from "firebase/auth";
import AuthContext from './AuthContext'
import Loader from './Loader'

function Register() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [showLoading, setShowLoading] = useState(false);
    
    
    const [showProfileExistsDialog, setShowProfileExistsDialog] = useState(false);
    const navigate = useNavigate();

    function handleEmailChange(val) {
        setEmail(val);
    }

    function handlePasswordChange(val) {
        setPassword(val);
    }

    function handleDialogActionClose() {
        setShowProfileExistsDialog(false)
    }

    async function handleRegisterProfile() {
        if(email.length === 0 || password.length === 0) {
            console.log("Email empty");
            return;
        }

        setShowLoading(true)
        const result  = await registerProfile();
        if(result === 200) navigate("/design")
        else if(result === 500) {
            setErrorText("This profile already exists.");
        }
        else {
            setErrorText("There was an error registering this profile.")
        }
        setShowLoading(false);
    }

    async function registerProfile() {
        console.log("Registering profile")
        
        return await createUser(email, password);
    }

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
          await handleRegisterProfile()
        }
      };

  return (
    <ProfileContainer>
    <Loader show={showLoading} />
    <ChoiceContainer>
        {showProfileExistsDialog && <InfoDialog 
            show={showProfileExistsDialog} 
            setOpen={setShowProfileExistsDialog}
            title="Profile Exists"
            content="This profile email already exists."
            action={handleDialogActionClose} />}

        <ProfileHeroImageContainer style={{'marginBottom': '50px'}}>
            <ProfileHeroImage src={ProfileImg} />
        </ProfileHeroImageContainer>
        <DivHolder width={"70%"} column style={{'marginBottom': '30px'}}>
        <ChoiceBox
            
            size="small"
            placeholder='Enter your email' 
            value={email} 
            onChange={(e) => handleEmailChange(e.target.value)}
            inputProps={{
                style: {
                    fontFamily: "Poppins",
                    textAlign: "center",
                    fontSize: "0.8rem"
                }
            }}
            >
            
        </ChoiceBox>
        <ChoiceBox

            sx={{'marginTop': '15px'}}
            size="small"
            type={showPassword ? "text" : "password"}
            fontSize={"0.8rem"}
            placeholder='Enter your password' 
            value={password}
            onKeyDown={handleKeyDown} 
            onChange={(e) => handlePasswordChange(e.target.value)}
            inputProps={{
                style: {
                    fontFamily: "Poppins",
                    textAlign: "center",
                    fontSize: "0.8rem"
                }
            }}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? <StyledHidePasswordIcon color={Colors.dark_blue} onClick={(e) => setShowPassword(prevState => !prevState)} fontSize='medium' />  :
                    <StyledPasswordIcon color={Colors.dark_blue} onClick={(e) => setShowPassword(prevState => !prevState)} fontSize='medium' />}
                  </InputAdornment>
                ),
              }}
            >
            
        </ChoiceBox>
        <Text style={{'marginTop': '15px'}} color={Colors.light_purple_plus} size={Sizes.small}>{errorText}</Text>
        </DivHolder>
        
        <DivHolder column>
        <LoginButton onClick={handleRegisterProfile} style={{'marginBottom': '10px'}} fullWidth>Register Profile</LoginButton>
        <LoginButton fullWidth onClick={() => navigate("/")}>Back</LoginButton>
        </DivHolder>
        
    </ChoiceContainer>
    <AppFooter />
    </ProfileContainer>
    
  )
}

export default Register