import React, { useState, useEffect } from 'react'
import { ChoiceContainer, Colors, FooterBar, Sizes, Weights } from './styles/styled.container'
import { ChoiceBox, Text } from './styles/styled.text'
import { ProfileContainer, ProfileHeroImage, ProfileHeroImageContainer } from './styles/styled.profile'
import ProfileImg from "./imgs/me.png"
import AppFooter from './AppFooter'
import AppHeader from './AppHeader'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { StyledSearchIcon } from './styles/styled.icons'
import Splash from './Splash'


function Choice() {
    const [value, setValue] = useState("")
    const navigate = useNavigate();
    

    function handleChangeValue(val) {
        setValue(val)
    }

    function handleNavigateProfile() {

        if(value.length === 0) return;

        const trimmedValue = value.toLowerCase();
          navigate(`/${trimmedValue}`);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          handleNavigateProfile()
        }
      };

  return (
    <ProfileContainer>
    <AppHeader />
    <ChoiceContainer>
    <Text style={{'marginBottom': '10px', padding: '0px 40px'}} weight={Weights.bold} colour={Colors.white} size={Sizes.xlarge}>Reach the world with one profile.</Text>
        <ProfileHeroImageContainer style={{'marginBottom': '50px'}}>
            <ProfileHeroImage src={ProfileImg} />
        </ProfileHeroImageContainer>
        
        <ChoiceBox
            placeholder='' 
            value={value} 
            onKeyDown={handleKeyDown}
            InputProps={{
                
                endAdornment: (
                    <IconButton onClick={handleNavigateProfile}><StyledSearchIcon color={Colors.dark_purple_plus} /></IconButton>
                )
            }}
            
            onChange={(e) => handleChangeValue(e.target.value)}
            inputProps={{
                style: {
                    fontFamily: "Poppins",
                    textAlign: "center"
                }
            }}
            >
            
        </ChoiceBox>

        <Text style={{'maxWidth': '300px', 'marginTop': '15px'}} size={Sizes.xtiny} colour={`${Colors.off_grey}`}>Find someone you know by entering their email or Profile Card link</Text>
        
    </ChoiceContainer>
    <Splash />
    <AppFooter />
    </ProfileContainer>
    
  )
}

export default Choice