import React, {useContext, useState} from 'react'
import { ProfileContactContainer } from './styles/styled.profile'
import { ContentHolder } from './styles/styled.container'
import AuthContext from './AuthContext'
import { ContactItem, ContactItemHolder } from './styles/styled.contact';
import { StyledAddressIcon, StyledEmailIcon, StyledPhoneIcon } from './styles/styled.icons';
import { InputAdornment, TextField } from '@mui/material';
import { Input } from './Input';
import { Text } from './styles/styled.text';

function decodeHTMLEntities(text) {
    const element = document.createElement("textarea");
    element.innerHTML = text;
    return element.value;
  }

function ContactBar({preview, propProfileDesign, propProfileInfo}) {

    const {profileDesign, setProfileDesign, profileInfo, setProfileInfo} = useContext(AuthContext);

    function handleProfileContactNumberChange(e) {
        setProfileInfo((prevState) => ({ ...prevState, contact_number: e.target.value }));
    }

    function handleProfileContactEmailChange(e) {
        setProfileInfo((prevState) => ({ ...prevState, contact_email: e.target.value }));
    }

  return (
    <ProfileContactContainer>
        <ContentHolder>
            
            <ContactItemHolder>
                {(preview ? propProfileDesign.showContactNumber : profileDesign.showContactNumber) &&
                <ContactItem align={preview ? propProfileDesign.contactDescriptionAlign : profileDesign.contactDescriptionAlign}>
                    <StyledPhoneIcon fontSize='large' style={{'marginBottom': '15px'}} color={preview ? propProfileDesign.contactDescriptionColor : profileDesign.contactDescriptionColor} />
                    
                    {preview ?
                    <Text 
                    colour={propProfileDesign.contactDescriptionColor}
                    size={propProfileDesign.contactDescriptionSize}
                    lineHeight={'1.5'}
                    align={propProfileDesign.contactDescriptionAlign}
                    weight={propProfileDesign.contactDescriptionWeight}
                    >{decodeHTMLEntities(propProfileInfo.contact_number)}</Text>
                    :

                    <Input 
                    placeholder={"Contact Number"}
                    field="contact_number"
                    myVal={profileInfo.contact_number} 
                    colour={profileDesign.contactDescriptionColor}
                    size={profileDesign.contactDescriptionSize}
                    align={profileDesign.contactDescriptionAlign}
                    weight={profileDesign.contactDescriptionWeight}
                    
                    lineHeight={"1.3"}
                    
                    padding={"0px"}

                    />}

                </ContactItem>}
                {(preview ? propProfileDesign.showContactEmail : profileDesign.showContactEmail) &&
                <ContactItem align={preview ? propProfileDesign.contactDescriptionAlign : profileDesign.contactDescriptionAlign}>
                    <StyledEmailIcon fontSize='large' style={{'marginBottom': '15px'}} color={preview ? propProfileDesign.contactDescriptionColor : profileDesign.contactDescriptionColor} />
                    {preview ?

                    <Text 
                    colour={propProfileDesign.contactDescriptionColor}
                    size={propProfileDesign.contactDescriptionSize}
                    lineHeight={'1.5'}
                    align={propProfileDesign.contactDescriptionAlign}
                    weight={propProfileDesign.contactDescriptionWeight}
                    >{decodeHTMLEntities(propProfileInfo.contact_email)}</Text> :

                    <Input 
                    placeholder={"Your Email"}
                    field="contact_email"
                    myVal={profileInfo.contact_email} 
                    colour={profileDesign.contactDescriptionColor}
                    size={profileDesign.contactDescriptionSize}
                    align={profileDesign.contactDescriptionAlign}
                    weight={profileDesign.contactDescriptionWeight}
                    
                    lineHeight={"1.3"}
                    
                    padding={"0px"}

                    />}

                
                </ContactItem>}
                {(preview ? propProfileDesign.showContactAddress : profileDesign.showContactAddress) &&
                <ContactItem align={preview ? propProfileDesign.contactDescriptionAlign : profileDesign.contactDescriptionAlign}>
                    <StyledAddressIcon fontSize='large' style={{'marginBottom': '15px'}} color={preview ? propProfileDesign.contactDescriptionColor : profileDesign.contactDescriptionColor} />
                    {preview ? 

                    <Text 
                    colour={propProfileDesign.contactDescriptionColor}
                    size={propProfileDesign.contactDescriptionSize}
                    lineHeight={'1.5'}
                    align={propProfileDesign.contactDescriptionAlign}
                    weight={propProfileDesign.contactDescriptionWeight}
                    >{decodeHTMLEntities(propProfileInfo.contact_address)}</Text> :

                    <Input 
                    placeholder={"Your Address"}
                    field="contact_address" 
                    myVal={profileInfo.contact_address}
                    colour={profileDesign.contactDescriptionColor}
                    size={profileDesign.contactDescriptionSize}
                    align={profileDesign.contactDescriptionAlign}
                    weight={profileDesign.contactDescriptionWeight}
                    
                    lineHeight={"1.3"}
                    
                    padding={"0px"}

                    />}

                
                </ContactItem>}
            </ContactItemHolder>
        </ContentHolder>
    </ProfileContactContainer>
  )
}

export default ContactBar