import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function InfoDialog(props) {
  const {open, setOpen, title, content, extra_content, action} = props;

  const handleClose = () => {
    setOpen(false);
  };

  function handleAction() {
    
    if(props.action) action();
    else handleClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
          <Divider sx={{marginTop: '10px'}} />
          <Typography sx={{'marginTop': '10px', color: "blue"}}>{extra_content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}