import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, IconButton, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { StyledShareIcon } from './styles/styled.icons';
import { Colors } from './styles/styled.container';
import { useNavigate } from 'react-router-dom';

export default function PublishedDialog(props) {
  const {open, setOpen, title, content, extra_content, link, action} = props;
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

 
  function handleAction() {
    
    if(props.action) action();
    else handleClose();
  }

  const handleShareProfile = () => {

    if (navigator.share) {
      navigator.share({
        title: "Profile Card Link",
        url: link,
      })
        .then(() => {

        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
    } else {


      window.open(link, '_blank');
    }
  };

  function previewLink() {
    window.open(link, '_blank');
  }

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
        <Typography sx={{'fontSize': '1rem', 'marginTop': '0px', color: `${Colors.dark_purple_plus}`}}>{content}</Typography>

          <TextField sx={{'margin': '10px 0px'}} 
          size='small' 
          fullWidth 
          value={link}
          inputProps={{
            style: {
              fontSize: '0.8rem',
              fontWeight: '500'
            }
          }} 
          InputProps={{
            readOnly: true,
            endAdornment: (
                <IconButton onClick={handleShareProfile}><StyledShareIcon fontSize='small' color={Colors.dark_blue} /></IconButton>
            )
          }}
          />
          <Typography sx={{'marginTop': '10px', color: "blue"}}>{extra_content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} autoFocus>
            Close
          </Button>
          <Button onClick={previewLink} autoFocus>
            Preview Profile
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}