import styled from "styled-components";

export const ContactItemHolder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ContactItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.align === "left" ? "flex-start" : props.align === "center" ? "center" : "flex-end"};
    justify-content: center;
    width: 100%;
    min-width: 300px;
    margin: 10px 0px;

    

    
`