import React from 'react'
import { SpinnerContainer, Spinner, SpinnerMessage, LoaderDots, Dot } from './styles/styled.spinner'

function Loader({show, loaderMessage}) {
  return (
    <>
    {show && <SpinnerContainer>
        {/* <Spinner /> */}
        <LoaderDots>
        <Dot delay={0} />
        <Dot delay={0.2} />
        <Dot delay={0.4} />
        </LoaderDots>
        {loaderMessage && <SpinnerMessage>{loaderMessage}</SpinnerMessage>}
      </SpinnerContainer>}
    </>
  )
}



export default Loader