import styled from "styled-components";
import { Colors } from "./styled.container";

export const SplashContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const SplashItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 800px;
    margin-bottom: 50px;
    align-self: center;
`

export const SplashImageContainer = styled.div`
    max-width: 400px;
    margin-top: 50px;
    display: flex;
    align-self: center;
`

export const SplashImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const SplashButton = styled.div`
    cursor: pointer;
    padding: 20px 40px;
    background-color: ${Colors.white};
    color: ${Colors.deep_blue};
    text-align: center;
    text-transform: uppercase;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 800;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: ${Colors.light_purple_plus};
    }
`