import styled from "styled-components";
import { Colors } from "./styled.container";

export const SettingsHeaderTitle = styled.div`
    user-select: none;
    color: #e5e5e5;
    font-size: 0.9rem;
`

export const SettingsSection = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 10px;
    flex-direction: column;
`

export const SettingsTitle = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    user-select: none;
    flex: 1;
    max-width: 30%;
    margin-bottom: 5px;
`

export const SettingsDetailItem = styled.div`
    display: flex;
    margin: 5px 5px;
    padding: 0px 0px;
    flex: 1;
    min-width: 200px;

    align-items: center;
    display: flex;

`

export const SettingsDetailItemBlock = styled.div`
    margin: 5px 0px;
    display: flex;
    flex-wrap: wrap;
    
    align-items: center;
    

`

export const SettingsDetailItemText = styled.div`
    font-size: 0.7rem;
    user-select: none;
    font-weight: 500;

    width: ${props => props.fullWidth ? props.fullWidth : "80px"};
    text-transform: uppercase;
    margin-right: 10px;
    color: ${Colors.dark_purple_plus};
`

export const ColorPickerContainer = styled.div`
    display: flex;
    z-index: 3;
    top: 0;
    margin: ${props => props.margin ? props.margin : "0px"};
    margin-right: ${props => props.marginRight ? props.marginRight : "0px"};
    position: absolute;
`

export const ColorPickerCloseButton = styled.div`
    right: 0;
    z-index: 10;
    margin-top: 0px;
    cursor: pointer;
    margin-left: 5px;
`