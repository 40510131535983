import { useContext, useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";
import AuthContext from "./AuthContext";
import { InputAdornment, TextField } from "@mui/material";
import { StyledRemoveIcon } from "./styles/styled.icons";

function decodeHTMLEntities(text) {
  const element = document.createElement("textarea");
  element.innerHTML = text;
  return element.value;
}

export const Input = ({field, myVal, placeholder, preview, align, colour, size, weight, marginTop, lineHeight, letterSpacing, textTransform, position}) => {
  const [value, setValue] = useState();
  const {profileDesign, setProfileInfo, profileInfo} = useContext(AuthContext)

  useEffect(() => {
    if (myVal) {
      setValue(decodeHTMLEntities(myVal));
    }
  }, [myVal]);

  
  const debouncedRequest = useDebounce(() => {
    setProfileInfo(prevState => ({...prevState, [field]: value.replace(/\n/g, '&#10;')}))
  })

  const onChange = (e) => {
    const value = e.target.value;
    //const formattedValue = value.replace(/\n/g, '&#10;'); // Convert new lines to &#10; (HTML entity for line break)

    setValue(value);

    debouncedRequest();
  }

    return <TextField 
    multiline
    size='small'
    fullWidth
    sx={{'zIndex': '2', 'position': position || "static", 'marginTop': marginTop || "0px"}}
    placeholder={`${placeholder}`}
    value={value}
    defaultValue={myVal ? decodeHTMLEntities(myVal) : ""}
    onChange={onChange}
    inputProps={{ 
        style: {
            margin: '0px', 
            padding: '0px',
            fontFamily: `${profileDesign.profileFont}`,
            lineHeight: lineHeight || 1.5,
            letterSpacing: letterSpacing || 1,
            fontFamily: profileDesign.profileFont,
            textAlign: `${align}`,
            color: `${colour}`,
            fontSize: `${size}`,
            fontWeight: `${weight}`,
            textTransform: textTransform || "none" 
            
        },
        }}
    />
}

export const SectionTitleInput = ({sectionInfo, myVal, handleDeleteSection}) => {
  const [value, setValue] = useState();
  const {profileDesign, sections, setSections} = useContext(AuthContext)

  useEffect(() => {
    if (myVal) {
      setValue(decodeHTMLEntities(myVal));
    }
  }, [myVal]);

  const debouncedRequest = useDebounce(() => {

    setSections(prevState => prevState.map(section => {
        if(section.id === sectionInfo.id) return {...section, title: value.replace(/\n/g, '&#10;')}
        else return section
      
    }))
  })

  const onChange = (e) => {
    const value = e.target.value;
    setValue(value);

    debouncedRequest();
  }

  return <TextField 
  multiline
  size='small'
  fullWidth
  sx={{'position': 'relative'}}

  placeholder='Section Heading'
  value={value}
  onChange={onChange}
  inputProps={{ 
      style: { 
          margin: '0px',
          padding: '0px',
          fontFamily: profileDesign.profileFont,
          lineHeight: 1.3,
          textAlign: `${profileDesign.sectionTitleAlign}`,
          color: `${profileDesign.sectionTitleColor}`,
          fontSize: `${profileDesign.sectionTitleSize}`,
          fontWeight: `${profileDesign.sectionTitleWeight}` 
      },
      }}
      InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{'position': 'absolute', "right": "0", "marginRight": '10px'}}>
              <StyledRemoveIcon onClick={() => handleDeleteSection(sectionInfo)} fontSize='large' />
            </InputAdornment>
          ),
          
        }}
  />
}

export const SectionDescriptionInput = ({sectionInfo, myVal}) => {
  const [value, setValue] = useState();
  const {profileDesign, sections, setSections} = useContext(AuthContext)

  useEffect(() => {
    if (myVal) {
      setValue(decodeHTMLEntities(myVal));
    }
  }, [myVal]);

  const debouncedRequest = useDebounce(() => {

    setSections(prevState => prevState.map(section => {
      
        if(section.id === sectionInfo.id) return {...section, description: value.replace(/\n/g, '&#10;')}
        else return section
      
    }))
  })

  const onChange = (e) => {
    const value = e.target.value;
    setValue(value);

    debouncedRequest();
  }

  return <TextField 
  multiline
  fullWidth
  size='small'
  value={value}
  placeholder='Section Description'
  onChange={onChange}
  inputProps={{ 
      style: { 
          margin: '0px',
          padding: '0px',
          fontFamily: profileDesign.profileFont,
          lineHeight: 1.5,
          textAlign: `${profileDesign.sectionDescriptionAlign}`,
          color: `${profileDesign.sectionDescriptionColor}`,
          fontSize: `${profileDesign.sectionDescriptionSize}`,
          fontWeight: `${profileDesign.sectionDescriptionWeight}` 
      } 
      }}
  />
}