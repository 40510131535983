import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import AuthContext from './AuthContext'
import { ProfileHeroContainer, ProfileHeroDescriptionContainer, ProfileHeroImage, ProfileHeroImageContainer, ProfileSocialContainer, ProfileSocialIcon, ProfileSocialIconContainer } from './styles/styled.profile';
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Slide, Snackbar, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Align, Colors, ContentHolder, DesignButton, DesignImage, FileButton, HeroContentHolder, ProfileImageStyles, Weights } from './styles/styled.container';
import { Text } from './styles/styled.text';
import { Sizes } from './styles/styled.container';
import { ColorPickerCloseButton, ColorPickerContainer, SettingsDetailItem, SettingsDetailItemBlock, SettingsDetailItemText, SettingsSection, SettingsTitle } from './styles/styled.settings';
import { ChromePicker } from 'react-color';
import { StyledBehanceIcon, StyledFacebookIcon, StyledGithubIcon, StyledInstagramIcon, StyledLinkedInIcon, StyledRemoveIcon, StyledTwitterIcon, StyledWebsiteIcon, StyledYoutubeIcon } from './styles/styled.icons';
import WandImg from "./imgs/wand.png"
import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Slider from '@mui/material/Slider';

import { Input } from './Input';
import Loader from './Loader';
import ProfileImageViewer from './ProfileImageViewer';
import PexelsViewer from './PexelsViewer';


const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
    
    const {currentWindow} = props
    const [open, setOpen] = React.useState(props.open);
    const {profileMeta, profileInfo, setProfileInfo, profileDesign, setProfileDesign, socialSection, setSocialSection} = useContext(AuthContext)
    const [showBackgroundPicker, setShowBackgroundPicker] = useState(false);
    const [showCardBackgroundPicker, setShowCardBackgroundPicker] = useState(false);
    const [showTitlePicker, setShowTitlePicker] = useState(false);
    const [showDescriptionPicker, setShowDescriptionPicker] = useState(false)
    const [showSocialPicker, setShowSocialPicker] = useState(false)
    const [showSocialIconPicker, setShowSocialIconPicker] = useState(false)
    const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(profileDesign.heroBackgroundColor);
    const [selectedCardBackgroundColor, setSelectedCardBackgroundColor] = useState(profileDesign.heroCardBackgroundColor);
    
    const [selectedTitleColor, setSelectedTitleColor] = useState(profileDesign.heroTitleColor);
    const [selectedDescriptionColor, setSelectedDescriptionColor] = useState(profileDesign.heroDescriptionColor);
    const [selectedSocialBackgroundColor, setSelectedSocialBackgroundColor] = useState(profileDesign.socialBackgroundColor);
    const [selectedSocialIconColor, setSelectedSocialIconColor] = useState(profileDesign.socialLinkColor)

    const [showLoading, setShowLoading] = useState(false);
    const [showPexelsDialog, setShowPexelsDialog] = useState(false)

      
    const handleHeroBackgroundImageClick = () => {
        //heroBackgroundInputRef.current.click();
        setShowPexelsDialog(true)
    };

    function handlePexelsChangeHeroBackground(src, photographer) {
      setShowPexelsDialog(false)
      setProfileInfo(prevState => ({...prevState,
        hero_background_image: src
      }));

    }


    function handleRemoveBackgroundImage() {
      setProfileInfo(prevState => ({...prevState, hero_background_image: ""}));
    }


    const handleBackgroundTextFieldClick = () => {

      setShowBackgroundPicker(true);
    };

    const handleCardBackgroundTextFieldClick = () => {

      setShowCardBackgroundPicker(true);
    };

    const handleTextFieldClick = () => {

      setShowTitlePicker(true);
    };

    const handleDescriptionFieldClick = () => {

      setShowDescriptionPicker(true)
    }

    const handleSocialBackgroundFieldClick = () => {
      setShowSocialPicker(true)
    }

    const handleSocialIconFieldClick = () => {
      setShowSocialIconPicker(true)
    }

    const handleBackgroundColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedBackgroundColor(rgbaColor);
      setProfileDesign(prevState => ({...prevState, heroBackgroundColor: rgbaColor}))
    };

    const handleCardBackgroundColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedCardBackgroundColor(rgbaColor);
      setProfileDesign(prevState => ({...prevState, heroCardBackgroundColor: rgbaColor}))
    };
  
    const handleTitleColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedTitleColor(rgbaColor)
      setProfileDesign(prevState => ({...prevState, heroTitleColor: rgbaColor}))
    };

    const handleDescriptionColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedDescriptionColor(rgbaColor)
      setProfileDesign(prevState => ({...prevState, heroDescriptionColor: rgbaColor}))
    };

    const handleSocialBackgroundColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedDescriptionColor(rgbaColor)
      setProfileDesign(prevState => ({...prevState, socialBackgroundColor: rgbaColor}))
    };

    const handleSocialIconColorChange = (color) => {
      const { r, g, b, a } = color.rgb;
      const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      setSelectedDescriptionColor(rgbaColor)
      setProfileDesign(prevState => ({...prevState, socialLinkColor: rgbaColor}))
    };
  
    const handlePickerClose = () => {
      setShowDescriptionPicker(false)
      setShowTitlePicker(false);
      setShowBackgroundPicker(false)
      setShowCardBackgroundPicker(false)
      setShowSocialPicker(false)
      setShowSocialIconPicker(false)
    };
    
    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

  
    // This is used only for the example
    const container = currentWindow !== undefined ? () => currentWindow().document.body : undefined;

    function resetBackgroundColor(e) {
      e.stopPropagation();
      setSelectedBackgroundColor("none")
      setProfileDesign(prevState => ({...prevState, heroBackgroundColor: Colors.transparent}))
    }

    function resetCardBackgroundColor(e) {
      e.stopPropagation();
      setSelectedCardBackgroundColor("none")
      setProfileDesign(prevState => ({...prevState, heroCardBackgroundColor: Colors.transparent}))
    }

    function resetSocialBackgroundColor(e) {
      e.stopPropagation();
      setSelectedSocialBackgroundColor("none")
      setProfileDesign(prevState => ({...prevState, socialBackgroundColor: Colors.transparent}))
    }

    function handleSocialIconSizeChange(e) {
      
      setProfileDesign(prevState => ({...prevState, socialIconSize: e.target.value}))

    }

    function handleSocialFacebookChecked(e) {
      setSocialSection(prevState => ({...prevState, facebook_status: e.target.checked}))
    }

    function handleSocialInstagramChecked(e) {
      setSocialSection(prevState => ({...prevState, instagram_status: e.target.checked}))
    }

    function handleSocialTwitterChecked(e) {
      setSocialSection(prevState => ({...prevState, twitter_status: e.target.checked}))
    }

    function handleSocialLinkedinChecked(e) {
      setSocialSection(prevState => ({...prevState, linkedin_status: e.target.checked}))
    }

    function handleSocialWebChecked(e) {
      setSocialSection(prevState => ({...prevState, web_status: e.target.checked}))
    }

    function handleSocialYoutubeChecked(e) {
      setSocialSection(prevState => ({...prevState, youtube_status: e.target.checked}))
    }

    function handleSocialGithubChecked(e) {
      setSocialSection(prevState => ({...prevState, github_status: e.target.checked}))
    }

    function handleSocialBehanceChecked(e) {
      setSocialSection(prevState => ({...prevState, behance_status: e.target.checked}))
    }

    /// ----
    function handleSocialFacebookURLChange(e) {
      setSocialSection(prevState => ({...prevState, facebook_link: e.target.value}))
    }

    function handleSocialInstagramURLChange(e) {
      setSocialSection(prevState => ({...prevState, instagram_link: e.target.value}))
    }

    function handleSocialTwitterURLChange(e) {
      setSocialSection(prevState => ({...prevState, twitter_link: e.target.value}))
    }

    function handleSocialLinkedinURLChange(e) {
      setSocialSection(prevState => ({...prevState, linkedin_link: e.target.value}))
    }

    function handleSocialWebURLChange(e) {
      setSocialSection(prevState => ({...prevState, web_link: e.target.value}))
    }

    function handleSocialYoutubeURLChange(e) {
      setSocialSection(prevState => ({...prevState, youtube_link: e.target.value}))
    }

    function handleSocialGithubURLChange(e) {
      setSocialSection(prevState => ({...prevState, github_link: e.target.value}))
    }

    function handleSocialBehanceURLChange(e) {
      setSocialSection(prevState => ({...prevState, behance_link: e.target.value}))
    }

    


    function handleTitleSizeChange(e) {
      
      setProfileDesign(prevState => ({...prevState, heroTitleSize: e.target.value}))

    }

    function handleTitleAlignChange(e) {
      
      setProfileDesign(prevState => ({...prevState, heroTitleAlign: e.target.value}))

    }

    function handleTitleWeightChange(e) {
      setProfileDesign(prevState => ({...prevState, heroTitleWeight: e.target.value}))

    }

    function handleDescriptionSizeChange(e) {
      
      setProfileDesign(prevState => ({...prevState, heroDescriptionSize: e.target.value}))

    }

    function handleDescriptionAlignChange(e) {
      
      setProfileDesign(prevState => ({...prevState, heroDescriptionAlign: e.target.value}))

    }

    function handleDescriptionWeightChange(e) {
      setProfileDesign(prevState => ({...prevState, heroDescriptionWeight: e.target.value}))

    }

    const handleProfileImageSizeChange = (e, newValue) => {
      
      setProfileDesign(prevState => ({...prevState, heroProfileImageSize: newValue}))
    };

    function profileImageValueText(value) {
      return value;
    }

    function handleProfileImageStyleChange(newValue) {
      setProfileDesign(prevState => ({...prevState, heroProfileImageStyle: newValue}))
    }

    
  
    return (
      <Root>
        <CssBaseline />
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(40% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
          }}
        />

        <Loader show={showLoading} />

        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          sx={{
            '& .MuiDrawer-paper': {
              maxWidth: 800,
              margin: '0 auto',
            },
          }}
          onClose={props.onClose}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            BackdropProps: {
              invisible: true, // Disable the backdrop
            },
            keepMounted: true,
            disableScrollLock: true
          }}
        >
        
        <PexelsViewer 
        open={showPexelsDialog} 
        setOpen={setShowPexelsDialog} 
        action={handlePexelsChangeHeroBackground} />
          
          <StyledBox
            sx={{
              position: 'absolute',
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Puller />
            <Typography sx={{ p: 2, color: 'text.secondary' }}>Hero Settings</Typography>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: '100%',
              overflow: 'auto',
            }}
          >
            <SettingsSection>
            {showBackgroundPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.heroBackgroundColor}
              onChange={handleBackgroundColorChange}
              onClick={handleBackgroundTextFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}

          {showCardBackgroundPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.heroCardBackgroundColor}
              onChange={handleCardBackgroundColorChange}
              onClick={handleCardBackgroundTextFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            
              
              <SettingsDetailItemBlock>
                <SettingsTitle style={{'marginRight': '10px'}}>Hero Background</SettingsTitle>
              <SettingsDetailItem>

                <TextField
                size='small'
                value={selectedBackgroundColor}
                onClick={handleBackgroundTextFieldClick}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledRemoveIcon color={Colors.dark_blue} onClick={(e) => resetBackgroundColor(e)} fontSize='medium' />
                    </InputAdornment>
                  ),
                }}
              />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>
            <SettingsDetailItemBlock>
                
              <SettingsDetailItem>
              <SettingsTitle style={{'marginRight': '10px'}}>Hero Image</SettingsTitle>
                
                <FileButton backgroundColor={`${Colors.dark_purple_plus}`} onClick={handleHeroBackgroundImageClick}>{profileInfo.hero_background_image?.length > 0 ? "Selected" : "Choose file"}</FileButton>
                <StyledRemoveIcon color={`${Colors.dark_purple_plus}`} onClick={handleRemoveBackgroundImage} />

              
              </SettingsDetailItem>
            </SettingsDetailItemBlock>
            <SettingsDetailItemBlock>
                <SettingsTitle style={{'marginRight': '10px'}}>Card Background</SettingsTitle>
              <SettingsDetailItem>

                <TextField
                size='small'
                value={selectedCardBackgroundColor}
                onClick={handleCardBackgroundTextFieldClick}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledRemoveIcon color={Colors.dark_blue} onClick={(e) => resetCardBackgroundColor(e)} fontSize='medium' />
                    </InputAdornment>
                  ),
                }}
              />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>
            </SettingsSection>
            <SettingsSection>
            {showTitlePicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.heroTitleColor}
              onChange={handleTitleColorChange}
              onClick={handleTextFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            
            <SettingsTitle>Profile Name</SettingsTitle>
            <SettingsDetailItemBlock>
              
            <SettingsDetailItem>
                <SettingsDetailItemText>Size</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.heroTitleSize}
                  onChange={handleTitleSizeChange}
                  fullWidth
                >
                  {Object.entries(Sizes).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Weight</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.heroTitleWeight}
                  onChange={handleTitleWeightChange}
                  fullWidth
                >
                  {Object.entries(Weights).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Align</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.heroTitleAlign}
                  onChange={handleTitleAlignChange}
                  fullWidth
                >
                  {Object.entries(Align).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Colour</SettingsDetailItemText>
                <TextField
                size='small'
                inputProps={{ readOnly: true }}
                value={selectedTitleColor}
                onClick={handleTextFieldClick}
                fullWidth
              />
            </SettingsDetailItem>

            

            </SettingsDetailItemBlock>
            
            </SettingsSection>

            <SettingsSection>

            {showDescriptionPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.heroDescriptionColor}
              onChange={handleDescriptionColorChange}
              onClick={handleDescriptionFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            <SettingsTitle>Profile Image</SettingsTitle>
            <SettingsDetailItemBlock>
              <SettingsDetailItem>
                <SettingsDetailItemText>Image Size</SettingsDetailItemText>
                <Slider
                  onChange={handleProfileImageSizeChange}
                  value={profileDesign.heroProfileImageSize}
                  valueLabelDisplay="auto" step={20} marks min={80} max={160}
                />
              </SettingsDetailItem>
            </SettingsDetailItemBlock>
            <SettingsDetailItemBlock>
              <SettingsDetailItem>
                <SettingsDetailItemText>Style</SettingsDetailItemText>
                <TextField size="small" fullWidth select value={profileDesign.heroProfileImageStyle} onChange={(e) => handleProfileImageStyleChange(e.target.value)}>
                  {ProfileImageStyles.map(style => {
                    
                    return <MenuItem key={style} value={style}>{style}</MenuItem>
                  })}
                </TextField>
              </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsTitle style={{'marginTop': '10px'}}>Profile Description</SettingsTitle>
            <SettingsDetailItemBlock>
            
            <SettingsDetailItem>
                <SettingsDetailItemText>Size</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.heroDescriptionSize}
                  onChange={handleDescriptionSizeChange}
                  fullWidth
                >
                  {Object.entries(Sizes).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Weight</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.heroDescriptionWeight}
                  onChange={handleDescriptionWeightChange}
                  fullWidth
                >
                  {Object.entries(Weights).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Align</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.heroDescriptionAlign}
                  onChange={handleDescriptionAlignChange}
                  fullWidth
                >
                  {Object.entries(Align).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>

            <SettingsDetailItem>
                <SettingsDetailItemText>Colour</SettingsDetailItemText>
                <TextField
                size='small'
                inputProps={{ readOnly: true }}
                value={selectedDescriptionColor}
                onClick={handleDescriptionFieldClick}
                fullWidth
              />
            </SettingsDetailItem>

            </SettingsDetailItemBlock>
            
            </SettingsSection>

            <SettingsSection>

            {showSocialPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.socialBackgroundColor}
              onChange={handleSocialBackgroundColorChange}
              onClick={handleSocialBackgroundFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            {showSocialIconPicker && (<ColorPickerContainer>
            <ChromePicker
              color={profileDesign.socialLinkColor}
              onChange={handleSocialIconColorChange}
              onClick={handleSocialIconFieldClick}
              onClose={handlePickerClose}
            />
              <ColorPickerCloseButton><StyledRemoveIcon onClick={handlePickerClose} color={Colors.dark_purple_plus} /></ColorPickerCloseButton>
              </ColorPickerContainer>
            
            )}
            <SettingsTitle>Social Bar</SettingsTitle>
            <SettingsDetailItemBlock>
                <SettingsTitle style={{'marginRight': '10px'}}>Background</SettingsTitle>
              <SettingsDetailItem>

                <TextField
                size='small'
                value={selectedSocialBackgroundColor}
                onClick={handleSocialBackgroundFieldClick}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledRemoveIcon color={Colors.dark_blue} onClick={(e) => resetSocialBackgroundColor(e)} fontSize='medium' />
                    </InputAdornment>
                  ),
                }}
              />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
            <SettingsDetailItem>
                <SettingsDetailItemText>Icon Size</SettingsDetailItemText>
                <TextField
                  select
                  size='small'
                  value={profileDesign.socialIconSize}
                  onChange={handleSocialIconSizeChange}
                  fullWidth
                >
                  {Object.entries(Sizes).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
                </TextField>
            </SettingsDetailItem>
            <SettingsDetailItem>
                <SettingsDetailItemText>Colour</SettingsDetailItemText>
                <TextField
                size='small'
                inputProps={{ readOnly: true }}
                value={selectedSocialIconColor}
                onClick={handleSocialIconFieldClick}
                fullWidth
              />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>


            <SettingsDetailItemBlock>
            <SettingsDetailItem>
            <FormControlLabel
              control={<Checkbox checked={socialSection.web_status} onChange={handleSocialWebChecked} />}
              label={<SettingsDetailItemText>Website</SettingsDetailItemText>}
            />
                <TextField
                  size='small'
                  placeholder='Paste your website url'
                  value={socialSection.web_link}
                  disabled={!socialSection.web_status}
                  onChange={handleSocialWebURLChange}
                  fullWidth
                />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
              
            <SettingsDetailItem>
            <FormControlLabel
              control={<Checkbox checked={socialSection.facebook_status} onChange={handleSocialFacebookChecked} />}
              label={<SettingsDetailItemText>Facebook</SettingsDetailItemText>}
            />
                <TextField
                  size='small'
                  placeholder='Paste your facebook url'
                  value={socialSection.facebook_link}
                  disabled={!socialSection.facebook_status}
                  onChange={handleSocialFacebookURLChange}
                  fullWidth
                />
            </SettingsDetailItem>
            
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
            <SettingsDetailItem>
            <FormControlLabel
              control={<Checkbox checked={socialSection.instagram_status} onChange={handleSocialInstagramChecked} />}
              label={<SettingsDetailItemText>Instagram</SettingsDetailItemText>}
            />
                <TextField
                  size='small'
                  placeholder='Paste your instagram url'
                  value={socialSection.instagram_link}
                  disabled={!socialSection.instagram_status}
                  onChange={handleSocialInstagramURLChange}
                  fullWidth
                />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
            <SettingsDetailItem>
            <FormControlLabel
              control={<Checkbox checked={socialSection.twitter_status} onChange={handleSocialTwitterChecked} />}
              label={<SettingsDetailItemText>Twitter</SettingsDetailItemText>}
            />
                <TextField
                  size='small'
                  placeholder='Paste your twitter url'
                  value={socialSection.twitter_link}
                  disabled={!socialSection.twitter_status}
                  onChange={handleSocialTwitterURLChange}
                  fullWidth
                />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
            <SettingsDetailItem>
            <FormControlLabel
              control={<Checkbox checked={socialSection.linkedin_status} onChange={handleSocialLinkedinChecked} />}
              label={<SettingsDetailItemText>Linkedin</SettingsDetailItemText>}
            />
                <TextField
                  size='small'
                  placeholder='Paste your linkedin url'
                  value={socialSection.linkedin_link}
                  disabled={!socialSection.linkedin_status}
                  onChange={handleSocialLinkedinURLChange}
                  fullWidth
                />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
            <SettingsDetailItem>
            <FormControlLabel
              control={<Checkbox checked={socialSection.youtube_status} onChange={handleSocialYoutubeChecked} />}
              label={<SettingsDetailItemText>Youtube</SettingsDetailItemText>}
            />
                <TextField
                  size='small'
                  placeholder='Paste your YouTube url'
                  value={socialSection.youtube_link}
                  disabled={!socialSection.youtube_status}
                  onChange={handleSocialYoutubeURLChange}
                  fullWidth
                />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
            <SettingsDetailItem>
            <FormControlLabel
              control={<Checkbox checked={socialSection.github_status} onChange={handleSocialGithubChecked} />}
              label={<SettingsDetailItemText>Github</SettingsDetailItemText>}
            />
                <TextField
                  size='small'
                  placeholder='Paste your GitHub url'
                  value={socialSection.github_link}
                  disabled={!socialSection.githubtube_status}
                  onChange={handleSocialGithubURLChange}
                  fullWidth
                />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>

            <SettingsDetailItemBlock>
            <SettingsDetailItem>
            <FormControlLabel
              control={<Checkbox checked={socialSection.behance_status} onChange={handleSocialBehanceChecked} />}
              label={<SettingsDetailItemText>Behance</SettingsDetailItemText>}
            />
                <TextField
                  size='small'
                  placeholder='Paste your Behance url'
                  value={socialSection.behance_link}
                  disabled={!socialSection.behance_status}
                  onChange={handleSocialBehanceURLChange}
                  fullWidth
                />
            </SettingsDetailItem>
            </SettingsDetailItemBlock>
            
            </SettingsSection>
            
          </StyledBox>
        </SwipeableDrawer>
      </Root>
    );
  }

  function decodeHTMLEntities(text) {
    const element = document.createElement("textarea");
    element.innerHTML = text;
    return element.value;
  }

const HeroSection = React.memo((props) => {

    const {profileMeta, profileDesign, profileInfo, setProfileInfo, socialSection} = useContext(AuthContext)
    const [showSettings, setShowSettings] = useState(false)
    const fileInputRef = useRef(null);
    
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [showProfileImageLoading, setShowProfileImageLoading] = useState(false);
    const [tempProfileImage, setTempProfileImage] = useState(null);
    const [showProfileImageViewer, setShowProfileImageViewer] = useState(false);



    const {propProfileDesign, propProfileInfo, propSocialSection, preview} = props; 
    
    function openUrl(url) {
      window.open(url, '_blank');
    }

    useEffect(() => {
      if(!showProfileImageViewer) setTempProfileImage(null)
    }, [showProfileImageViewer])

    const handleProfileImageClick = () => {
      if(!preview)
        fileInputRef.current.click();
    };

    const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      if (file) {

        if (file.size > 5 * 1024 * 1024) {
          setShowSnackbar(true)
          return;
        }
        // Perform any necessary validation or resizing of the image if needed
  
        // Update profile image
        setTempProfileImage(file)
        setShowProfileImageViewer(true)
        //handleProfileImageChange(file);
      }
    }


    function handleCloseProfileImageViewer() {
      setShowProfileImageViewer(false)
    }

    // function handleProfileImageChange(file) {
    //   const reader = new FileReader();
    //   reader.onload = () => {

    //     setProfileInfo(prevState => ({...prevState, image: reader.result}));
    //   };
    //   reader.readAsDataURL(file);
    // }
    function handleProfileImageChange(blob, file) {
      setShowProfileImageViewer(false)
      setShowProfileImageLoading(true);
      const fileExtension = file.name.split('.').pop(); // Get the file extension
      const fileName = `profile.${fileExtension}`; // Use a fixed filename like "profile.extension"
    
      const storageRef = ref(storage, `profiles/${profileMeta.uid}/${fileName}`);
    
      uploadBytes(storageRef, blob)
        .then((snapshot) => {
          return getDownloadURL(snapshot.ref);
        })
        .then((downloadURL) => {
          setProfileInfo(prevState => ({ ...prevState, image: downloadURL }));
          setShowProfileImageLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setShowProfileImageLoading(false);
        });
    }

    function handleCloseBar() {
      setShowSnackbar(false);
    }


  return (
    
    <ProfileHeroContainer backgroundImage={preview ? propProfileInfo?.hero_background_image : profileInfo?.hero_background_image} backgroundColor={preview ? propProfileDesign.heroBackgroundColor : profileDesign.heroBackgroundColor}>
    

      <Snackbar
          open={showSnackbar}
          autoHideDuration={2500}
          onClose={handleCloseBar}
          message="Please choose an image smaller than 5mb."
          action={null}
        />
      
      {!preview &&
      <DesignButton marginTop="100px" onClick={() => setShowSettings(true)}>
        <DesignImage src={WandImg} />
      </DesignButton>}

      <HeroContentHolder backgroundColor={preview ? propProfileDesign.heroCardBackgroundColor : profileDesign.heroCardBackgroundColor}>
        
            {showSettings && !preview && <SwipeableEdgeDrawer open={true} onClose={() => setShowSettings(false)} />}
            {showProfileImageViewer && <ProfileImageViewer 
              open={showProfileImageViewer}
              action={handleProfileImageChange}
              setOpen={setShowProfileImageViewer}
              file={tempProfileImage} />}

            <ProfileHeroImageContainer design={preview ? propProfileDesign.heroProfileImageStyle : profileDesign.heroProfileImageStyle} size={preview ? propProfileDesign.heroProfileImageSize : profileDesign.heroProfileImageSize} preview={preview} onClick={handleProfileImageClick}>
              {showProfileImageLoading && <Loader show={showProfileImageLoading} />} 
              <ProfileHeroImage src={preview ? propProfileInfo.image : profileInfo.image} />
            </ProfileHeroImageContainer>

            {preview ? 
              <Text 
                colour={propProfileDesign.heroTitleColor}
                size={propProfileDesign.heroTitleSize}
                align={propProfileDesign.heroTitleAlign}
                weight={propProfileDesign.heroTitleWeight}
                letterSpacing={"1.1px"}
                lineHeight={"1.3"}
                padding={"0px"}
              >
              {decodeHTMLEntities(propProfileInfo.name)}
              </Text>
            :
            <>
            
            <Input 
                placeholder={"Your Name"}
                field="name" 
                preview
                myVal={profileInfo.name}
                colour={profileDesign.heroTitleColor}
                size={profileDesign.heroTitleSize}
                align={profileDesign.heroTitleAlign}
                weight={profileDesign.heroTitleWeight}
                letterSpacing={"1.1px"}
                lineHeight={"1.3"}
                padding={"0px"}

              />

            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />
            </>}
            
            {preview ?

            <Text 
                colour={propProfileDesign.heroTitleColor}
                size={'0.6rem'}
                align={propProfileDesign.heroTitleAlign}
                weight={700}
                textTransform={"uppercase"}
                letterSpacing={"1.1px"}
                padding={"0px"}
                marginTop={'10px'}
            >
            {decodeHTMLEntities(propProfileInfo.catch)}
            </Text> :

            <Input 
                placeholder={"Enter your title"}
                field="catch"
                myVal={profileInfo.catch} 
                colour={profileDesign.heroTitleColor}
                size={'0.6rem'}
                align={profileDesign.heroTitleAlign}
                weight={700}
                textTransform={"uppercase"}
                letterSpacing={"1.1px"}
                padding={"0px"}
                marginTop={"5px"}
              />}

            <ProfileHeroDescriptionContainer>
              {preview ? 
              <Text
                lineHeight={'1.6'}
                colour={propProfileDesign.heroDescriptionColor}
                size={propProfileDesign.heroDescriptionSize}
                align={propProfileDesign.heroDescriptionAlign}
                weight={propProfileDesign.heroDescriptionWeight}
              >
              {decodeHTMLEntities(propProfileInfo.description)}
              </Text>
              :
              <Input 
                placeholder={"Enter something interesting about yourself"}
                field="description"
                lineHeight={'1.6'}
                myVal={profileInfo.description} 
                colour={profileDesign.heroDescriptionColor}
                size={profileDesign.heroDescriptionSize}
                align={profileDesign.heroDescriptionAlign}
                weight={profileDesign.heroDescriptionWeight}
              />}
 
            </ProfileHeroDescriptionContainer>

            {preview ? 
            
            (propSocialSection.web_status || propSocialSection.facebook_status ||propSocialSection.instagram_status || propSocialSection.linkedin_status || propSocialSection.youtube_status || propSocialSection.github_status || propSocialSection.twitter_status || propSocialSection.behance_status) &&
              <ProfileSocialContainer backgroundColor={propProfileDesign.socialBackgroundColor}>
              <ContentHolder>
              <ProfileSocialIconContainer>
                  {propSocialSection.web_status && <ProfileSocialIcon onClick={() => openUrl(propSocialSection.web_link)}><StyledWebsiteIcon color={propProfileDesign.socialLinkColor} sx={{ fontSize: `${propProfileDesign.socialIconSize}` }} /></ProfileSocialIcon> }
                  {propSocialSection.facebook_status && <ProfileSocialIcon onClick={() => openUrl(propSocialSection.facebook_link)}><StyledFacebookIcon color={propProfileDesign.socialLinkColor} sx={{ fontSize: `${propProfileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {propSocialSection.instagram_status && <ProfileSocialIcon onClick={() => openUrl(propSocialSection.instagram_link)}><StyledInstagramIcon color={propProfileDesign.socialLinkColor} sx={{ fontSize: `${propProfileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {propSocialSection.twitter_status && <ProfileSocialIcon onClick={() => openUrl(propSocialSection.twitter_link)}><StyledTwitterIcon color={propProfileDesign.socialLinkColor} sx={{ fontSize: `${propProfileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {propSocialSection.linkedin_status && <ProfileSocialIcon onClick={() => openUrl(propSocialSection.linkedin_link)}><StyledLinkedInIcon color={propProfileDesign.socialLinkColor} sx={{ fontSize: `${propProfileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {propSocialSection.youtube_status && <ProfileSocialIcon onClick={() => openUrl(propSocialSection.youtube_link)}><StyledYoutubeIcon color={propProfileDesign.socialLinkColor} sx={{ fontSize: `${propProfileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {propSocialSection.github_status && <ProfileSocialIcon onClick={() => openUrl(propSocialSection.github_link)}><StyledGithubIcon color={propProfileDesign.socialLinkColor} sx={{ fontSize: `${propProfileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {propSocialSection.behance_status && <ProfileSocialIcon onClick={() => openUrl(propSocialSection.behance_link)}><StyledBehanceIcon color={propProfileDesign.socialLinkColor} sx={{ fontSize: `${propProfileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                
              </ProfileSocialIconContainer>
              </ContentHolder>
              
            </ProfileSocialContainer>
            
            :

            (socialSection.web_status || socialSection.facebook_status || socialSection.instagram_status || socialSection.linkedin_status || socialSection.youtube_status || socialSection.github_status || socialSection.behance_status || socialSection.twitter_status) &&
              <ProfileSocialContainer backgroundColor={profileDesign.socialBackgroundColor}>
              <ContentHolder>
              <ProfileSocialIconContainer>
                  {socialSection.web_status && <ProfileSocialIcon onClick={() => openUrl(socialSection.web_link)}><StyledWebsiteIcon color={profileDesign.socialLinkColor} sx={{ fontSize: `${profileDesign.socialIconSize}` }} /></ProfileSocialIcon> }
                  {socialSection.facebook_status && <ProfileSocialIcon onClick={() => openUrl(socialSection.facebook_link)}><StyledFacebookIcon color={profileDesign.socialLinkColor} sx={{ fontSize: `${profileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {socialSection.instagram_status && <ProfileSocialIcon onClick={() => openUrl(socialSection.instagram_link)}><StyledInstagramIcon color={profileDesign.socialLinkColor} sx={{ fontSize: `${profileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {socialSection.twitter_status && <ProfileSocialIcon onClick={() => openUrl(socialSection.twitter_link)}><StyledTwitterIcon color={profileDesign.socialLinkColor} sx={{ fontSize: `${profileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {socialSection.linkedin_status && <ProfileSocialIcon onClick={() => openUrl(socialSection.linkedin_link)}><StyledLinkedInIcon color={profileDesign.socialLinkColor} sx={{ fontSize: `${profileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {socialSection.youtube_status && <ProfileSocialIcon onClick={() => openUrl(socialSection.youtube_link)}><StyledYoutubeIcon color={profileDesign.socialLinkColor} sx={{ fontSize: `${profileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {socialSection.github_status && <ProfileSocialIcon onClick={() => openUrl(socialSection.github_link)}><StyledGithubIcon color={profileDesign.socialLinkColor} sx={{ fontSize: `${profileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
                  {socialSection.behance_status && <ProfileSocialIcon onClick={() => openUrl(socialSection.behance_link)}><StyledBehanceIcon color={profileDesign.socialLinkColor} sx={{ fontSize: `${profileDesign.socialIconSize}` }} /></ProfileSocialIcon>}
              
              </ProfileSocialIconContainer>
              </ContentHolder>
              
            </ProfileSocialContainer>
            
            }
            
      </HeroContentHolder>
            
    </ProfileHeroContainer>
  )
})

export default HeroSection