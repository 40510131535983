import { Colors, Sizes, PageContainer, NavBar, MainContainer, ButtonNewSection, ButtonContainer, Align, Weights, ContentHolder, Fonts } from "./styles/styled.container";
import { Title, LogoText, Text } from "./styles/styled.text";
import { ProfileContainer, ProfileHeroContainer, ProfileHeroDescriptionContainer, ProfileHeroImage, ProfileHeroImageContainer, ProfileHolder, ProfileSocialContainer, ProfileSocialIcon, ProfileSocialIconContainer, ProfileSocialIconItem } from "./styles/styled.profile";
import React, { useContext, useEffect, useState } from "react";
import ProfileImg from "./imgs/me.png"
import { StyledFacebookIcon, StyledInstagramIcon, StyledLinkedInIcon, StyledWebsiteIcon } from "./styles/styled.icons";
import AuthContext from "./AuthContext";
import Section from "./Section";
import moment from "moment/moment";
import { SectionHolder } from "./styles/styled.section";
import HeroSection from "./HeroSection";
import Header from "./Header";
import Footer from "./Footer";
import ProfileDesign from "./ProfileDesign";
import ProfilePreview from "./ProfilePreview";
import { Route, Routes } from "react-router-dom";
import Choice from "./Choice";
import Login from "./Login";
import PasswordReset from "./PasswordReset";
import { doc, setDoc, updateDoc, query, collection, where, getDocs, getDoc} from 'firebase/firestore';
import { db, logout } from "./firebase";
import Register from "./Register";
import { useNavigate } from "react-router-dom";
import {auth} from "./firebase"

import { useAuthState } from "react-firebase-hooks/auth";
import PexelsViewer from "./PexelsViewer";

function App() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({});
  const [profileMeta, setProfileMeta] = useState("");
  const [user, loading] = useAuthState(auth);
  
  function generateRandomNumber() {
    const numbers = '0123456789';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let randomString = '';
  
    for (let i = 0; i < 5; i++) {
      randomString += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
  
    for (let i = 0; i < 5; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  
    return randomString;
  }

  const initialProfileDesign = {
    showHero: true,
    showSections: true,
    showFooter: true,
    showContactNumber: true,
    showContactEmail: true,
    showContactAddress: true,
    profileBackgroundColor: Colors.profile_background_default,
    profileFont: Fonts.Nunito,
    
    heroBackgroundColor: Colors.transparent,
    heroCardBackgroundColor: Colors.profile_background_default,

    heroTitleColor: Colors.white,
    heroTitleSize: Sizes.xlarge,
    heroTitleAlign: Align.center,
    heroTitleWeight: Weights.bold,
    heroCatchSize: Sizes.xtiny,
    heroCatchWeight: Weights.light,
    heroDescriptionColor: Colors.off_grey,
    heroDescriptionSize: Sizes.tiny,
    heroDescriptionAlign: Align.center,
    heroDescriptionWeight: Weights.medium,
    
    heroProfileImage: "",
    heroProfileImageSize: 100,
    heroProfileImageStyle: "Classic",

    socialBackgroundColor: Colors.off_grey,
    socialLinkColor: Colors.dark_purple,
    socialIconSize: Sizes.xlarge,
    sectionBackgroundColor: Colors.transparent,
    sectionTitleSize: Sizes.xlarge,
    sectionTitleAlign: Align.center,
    sectionTitleColor: Colors.white,
    sectionTitleWeight: Weights.bold,
    sectionDescriptionSize: Sizes.tiny,
    sectionDescriptionAlign: Align.center,
    sectionDescriptionColor: Colors.white,
    sectionDescriptionWeight: Weights.light,
    footerBackgroundColor: Colors.transparent,
    footerDescriptionSize: Sizes.large,
    footerDescriptionAlign: Align.center,
    footerDescriptionColor: Colors.white,
    footerDescriptionWeight: Weights.bold,
    contactDescriptionSize: Sizes.xtiny,
    contactDescriptionAlign: Align.center,
    contactDescriptionColor: Colors.white,
    contactDescriptionWeight: Weights.medium
  };

  const initialProfileInfo = {
    name: "",
    image: ProfileImg,
    background_image: "",
    photographer: "",
    catch: "",
    description: "",
    footer: "",
    contact_number: "",
    contact_email: "",
    contact_address: "",
    hero_background_image: "",
    card_background_image: "",
    section_background_image: "",
    footer_background_image: "",
  };

  const initialSocialSection = {
    web_status: true,
    web_link: "https://www.prolificsolutions.co.za",
    instagram_status: false,
    instagram_link: "",
    facebook_status: false,
    facebook_link: "",
    linkedin_status: true,
    linkedin_link: "https://www.linkedin.com/in/imraanmoosaprolific/",
    youtube_status: false,
    youtube_link: "",
    github_status: false,
    github_link: ""
  };
  const initialSections = [
    {
      index: 0,
      id: generateRandomNumber(),
      title: "",
      description: "",
      uploaded: false
    },
    {
      index: 1,
      id: generateRandomNumber(),
      title: "",
      description: "",
      uploaded: false
    }
  ];

  const [profileDesign, setProfileDesign] = useState({...initialProfileDesign});
  const [profileInfo, setProfileInfo] = useState({...initialProfileInfo});
  const [socialSection, setSocialSection] = useState({...initialSocialSection});
  const [sections, setSections] = useState([...initialSections]);

  const handleLogout = () => {
    
    setProfileDesign({...initialProfileDesign})
    setProfileInfo({...initialProfileInfo})
    setSocialSection({...initialSocialSection})
    setSections([...initialSections])
    setProfileMeta("");
    setCurrentUser({})
    logout();
  };

  const authContextValue = {
    profileMeta,
    setProfileMeta,
    currentUser,
    setCurrentUser,
    profileDesign,
    setProfileDesign,
    profileInfo,
    setProfileInfo,
    socialSection,
    setSocialSection,
    sections,
    setSections,
    handleLogout: handleLogout
  };
  

  useEffect(() => {
    if (loading) {
      return;
    }
    if(user) {
      const profileRef = doc(db, "profiles/" + user.uid);

      getDoc(profileRef).then(result => {
        const userData = result.data();
        if (!userData.published) {
          setProfileMeta({ published: false, link_url: "", uid: "" });
          setProfileDesign({ ...initialProfileDesign });
          setProfileInfo({ ...initialProfileInfo });
          setSocialSection({ ...initialSocialSection });
          setSections([...initialSections]);
        }
        else {
          setProfileMeta({...userData, uid: user.uid})
        }
      });
    }
  }, [user, loading]);

  return (
    <AuthContext.Provider value={authContextValue}>
      <PageContainer font={profileDesign.profileFont}>
        <MainContainer>

            <Routes>
              <Route path="/" element={<Choice />} />
              <Route path="/design" element={<ProfileDesign />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/resetpassword" element={<PasswordReset />} />
              <Route path="/:link" element={<ProfilePreview />} />
            </Routes>
        </MainContainer>
      </PageContainer>
    </AuthContext.Provider>
  );
}

export default App;
