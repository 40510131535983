import React from 'react'
import { SplashButton, SplashContainer, SplashImage, SplashImageContainer, SplashItemContainer } from './styles/styled.splash'
import { Text } from './styles/styled.text'
import { Colors, PublishButton, Sizes, Weights } from './styles/styled.container'
import ImageDesign from "./imgs/splash_design.png"
import ImageSocials from "./imgs/splash_socials.png"
import ImageSections from "./imgs/splash_sections.png"
import { useNavigate } from 'react-router-dom'

function Splash() {
    const navigate = useNavigate();

  return (
    <SplashContainer>
        <SplashItemContainer>
            <Text textTransform="uppercase" weight={Weights.bold} size={Sizes.xlarge} colour={Colors.light_purple}>Design your profile</Text>
            <Text style={{'marginTop': '5px'}} size={Sizes.tiny} colour={Colors.white}>Build an online presence for you or your brand in under 5 minutes. With no design or coding experience.</Text>
            <SplashImageContainer>
                <SplashImage src={ImageDesign} />
            </SplashImageContainer>
        </SplashItemContainer>

        <SplashItemContainer>
            <Text textTransform="uppercase" weight={Weights.bold} size={Sizes.xlarge} colour={Colors.light_purple}>Links to your social accounts</Text>
            <Text style={{'marginTop': '10px'}} size={Sizes.tiny} colour={Colors.white}>Tell the world who you are and start building better connections.</Text>
            <SplashImageContainer>
                <SplashImage src={ImageSocials} />
            </SplashImageContainer>
        </SplashItemContainer>

        <SplashItemContainer>
            <Text textTransform="uppercase" weight={Weights.bold} size={Sizes.xlarge} colour={Colors.light_purple}>Create sections</Text>
            <Text style={{'marginTop': '10px'}} size={Sizes.tiny} colour={Colors.white}>Add more information about you or your brand.</Text>
            <SplashImageContainer>
                <SplashImage src={ImageSections} />
            </SplashImageContainer>
        </SplashItemContainer>

        <SplashItemContainer style={{'marginBottom': '100px'}}>
        <Text style={{'marginBottom': '30px'}} textTransform="uppercase" weight={Weights.bold} size={Sizes.xlarge} colour={Colors.white}>Get started. It's free.</Text>
        <SplashButton onClick={() => navigate("/register")}>Register</SplashButton>
        </SplashItemContainer>
    </SplashContainer>
  )
}

export default Splash