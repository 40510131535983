import { createContext } from "react";

const AuthContext = createContext({
    profileMeta: null,
    setProfileMeta: () => {},
    currentUser: null,
    setCurrentUser: () => {},
    profileDesign: null,
    setProfileDesign: () => {},
    profileInfo: null,
    setProfileInfo: () => {},
    socialSection: null,
    setSocialSection: () => {},
    sections: null,
    setSections: () => {},
    handleLogout: () => {}
})

export default AuthContext