import React, { useContext, useEffect, useState } from 'react'
import AuthContext from './AuthContext'
import { FooterBar, MainContainer, PageContainer, Sizes, Weights } from './styles/styled.container';
import { ProfileContainer, ProfileHolder } from './styles/styled.profile';
import HeroSection from './HeroSection';
import { SectionHolder } from './styles/styled.section';
import Section from './Section';
import Footer from './Footer';
import { LogoText, Text } from './styles/styled.text';
import { Colors } from './styles/styled.container';
import AppFooter from './AppFooter';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebase';
import { useParams } from 'react-router-dom';
import Loader from './Loader';
import EmptyProfile from './EmptyProfile';
import FindBar from './FindBar';



function ProfilePreview() {
    
    const params = useParams();
    const userLink = params.link;
    const [profileDesign, setProfileDesign] = useState()
    const [profileInfo, setProfileInfo] = useState();
    const [socialSection, setSocialSection] = useState()
    const [sections, setSections] = useState()
    const [showLoading, setShowLoading] = useState(false);
    const [emptyProfile, setEmptyProfile] = useState(false);


    useEffect(() => {

        async function getDetails() {
            setShowLoading(true)
            await getProfileDetails();
            setShowLoading(false)
        }

        getDetails();

    }, [])

    function isValidEmail(email) {     
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    }

    async function getProfileDetails() {

        let profileRef;

        if(isValidEmail(userLink))
          profileRef = query(collection(db, "profiles"), where("email", "==", userLink));
        else 
          profileRef = query(collection(db, "profiles"), where("link_url", "==", userLink));
        const profileDocs = await getDocs(profileRef);
        
        if(profileDocs.docs.length > 0) {
            const user = profileDocs.docs[0].id;
            

            const profileDetailsRef = doc(db, "profiles/" + user + "/details/design");
          const profileDetailsDoc = await getDoc(profileDetailsRef);
          const profileDetailsData = profileDetailsDoc.data();
  
          const profileInfoRef = doc(db, "profiles/" + user + "/details/info");
          const profileInfoDoc = await getDoc(profileInfoRef);
          const profileInfoData = profileInfoDoc.data();
  
          const profileSocialSectionRef = doc(db, "profiles/" + user + "/details/social");
          const profileSocialSectionDoc = await getDoc(profileSocialSectionRef);
          const profileSocialSectionData = profileSocialSectionDoc.data();
  
          const sectionsRef = query(collection(db, "profiles/" + user + "/details/sections/list"));
          const sectionsDocs = await getDocs(sectionsRef);
          const profile_sections = sectionsDocs.docs.map(sectionDoc => {
            const data = sectionDoc.data();  
              return {...data, key: sectionDoc.id};
          })
          
          if(profileDetailsData) setProfileDesign(profileDetailsData);
          if(profileInfoData) setProfileInfo(profileInfoData);
          if(profileSocialSectionData) setSocialSection(profileSocialSectionData)
          if(profile_sections.length > 0) {
            const sortedSections = profile_sections.sort((a, b) => a.index - b.index);
            setSections(sortedSections)
          }
        } else {
            setEmptyProfile(true)
        }
    }
  return (
            <ProfileContainer
            backgroundImage={profileInfo?.background_image}
            background_color={profileDesign?.profileBackgroundColor}
            >
            <FindBar link_url={userLink} />
            <ProfileHolder >
              
                <Loader show={showLoading} />

                {emptyProfile && <EmptyProfile />}

                {profileDesign && profileDesign.showHero && <HeroSection preview propProfileDesign={profileDesign} propProfileInfo={profileInfo} propSocialSection={socialSection} />}
                {profileDesign && profileDesign.showSections &&
                <SectionHolder backgroundImage={profileInfo?.section_background_image} backgroundColor={profileDesign.sectionBackgroundColor}>

                    {sections.map((section, i) => {
                    return (
                    <Section preview propProfileDesign={profileDesign} index={i} key={section.id} sectionInfo={section} />
                    )
                })}

                </SectionHolder>}

                {profileDesign && profileDesign.showFooter && <Footer preview propProfileDesign={profileDesign} propProfileInfo={profileInfo} />}
                <AppFooter photographer={profileInfo?.photographer} />
            </ProfileHolder>
            </ProfileContainer>
            

  )
}

export default ProfilePreview