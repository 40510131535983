import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, IconButton, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { StyledExpandIcon, StyledShareIcon } from './styles/styled.icons';
import { Colors, DesignImage, DesignImageHolder, DivHolder } from './styles/styled.container';
import WandImg from "./imgs/wand.png";

export default function WelcomeDialog(props) {
  const {open, setOpen, action} = props;

  const handleClose = () => {
    setOpen(false);
  };

 
  function handleAction() {
    
    if(props.action) action();
    else handleClose();
  }


  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle align="center">
          Welcome to Profile Card!
        </DialogTitle>
        <DialogContent>
        <Typography sx={{'fontSize': '1rem', 'marginTop': '0px', 'textAlign': 'center', color: `${Colors.dark_blue}`}}>Getting started is so easy!</Typography>
        
        <DivHolder column={true} align="flex-start" style={{'marginTop': '20px'}}>
        <DesignImageHolder style={{'height': 'fit-content'}}>
        <StyledExpandIcon style={{'marginRight': '5px', 'fontSize': '55px'}} color={Colors.dark_purple_plus} />
        </DesignImageHolder>
        
            {/* <DesignImageHolder style={{'marginRight': '10px'}}>
            <StyledExpandIcon style={{'fontSize': '3rem'}} color={Colors.dark_purple_plus} />
            </DesignImageHolder> */}
            
            <Typography sx={{'fontSize': '0.8rem', 'fontWeight': '500', 'marginTop': '0px', color: `${Colors.dark_purple_plus}`}}>Use the icon on the top to select Profile Settings and set your profile background and show the sections you want.</Typography>
        </DivHolder>

        <DivHolder column={true} align="flex-start" style={{'marginTop': '20px'}}>
            <DesignImageHolder style={{'width': '45px', 'height': '45px', 'marginLeft': '5px', 'marginRight': '10px'}}>
            <DesignImage src={WandImg} />
            </DesignImageHolder>
            
            <Typography sx={{'fontSize': '0.8rem', 'fontWeight': '500', 'marginTop': '0px', color: `${Colors.dark_purple_plus}`}}>Use the design tool to customize each section of your profile and set your social links.</Typography>
        </DivHolder>

        <DivHolder align="space-around" style={{'marginTop': '20px'}}>
            
            <Typography sx={{'fontSize': '0.9rem', 'fontWeight': '700', 'marginTop': '0px', color: `${Colors.dark_blue}`}}>Once you're ready to go live - tap the Publish button at the top.</Typography>
        </DivHolder>

        
        
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}